<template>
  <header>
    <div class="d-flex justify-center header">
      <img v-if="merchantLogo" :src="merchantLogo" alt="logo" style="width: 50px; height: 50px; border-radius: 5px;" class="me-3"/>
      <div class="title">{{ merchantName }}</div>
    </div>
    <div class="d-flex justify-content-between align-items-center" v-if="isCheckoutPage">
      <div class="font-weight-bold ps-3">YOUR ORDER</div>
      <div class="pe-3" style="font-size: 11px">
        Waiting time less than 10 mins
      </div>
    </div>
  </header>
</template>

<script>
import { localStorageName } from "@/config/utils";

export default {
  name: "KioskHeader",
  props: {
    isCheckoutPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      merchantName: "",
      merchantLogo: "",
    };
  },
  created() {
    this.merchantName = localStorage.getItem(localStorageName('MERCHANT_BRAND_NAME')) ?? ''
    this.merchantLogo = localStorage.getItem(localStorageName('MERCHANT_LOGO')) ?? ''
  }
};
</script>

<style scoped>
header {
  position: fixed;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 2;
}

header > div:nth-child(2) {
  background: #000;
  color: #fff;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #124728;
  color: #ffffff;

;
  height: 70px;
}

.header .title {
  font-size: 28px;
  font-weight: bold;
}

.header img {
  height: 70px;
  width: auto;
}
</style>
