<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-show-modal-confirm-still-there"
      data-bs-toggle="modal"
      data-bs-target="#modalConfirmStillThere"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-hide-modal-confirm-still-there"
      data-bs-dismiss="modal"
      data-bs-target="#modalConfirmStillThere"
    ></button>
    <div
      class="modal fade"
      id="modalConfirmStillThere"
      tabindex="-1"
      aria-labelledby="modalConfirmStillThereLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="font-weight-bold" style="font-size: 24px">
              Are you still there?
            </div>
            <div class="mt-2">Due to inactivity, kiosk will reset in</div>
            <div
              class="mt-3 mb-3 font-weight-bold text-center"
              style="font-size: 32px"
            >
              00:{{ displayTime }}
            </div>
          </div>
          <div class="modal-footer d-flex flex-column">
            <button @click="continueMyOrder()" class="btn-continue-my-order">
              Continue my order
            </button>
            <button @click="resetNow()" class="btn-reset-now mt-2">Reset Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirmStillThere",
  props: {
    keyReload: {
      required: true,
    },
    continueMyOrder: {
      type: Function,
      default: () => {},
    },
    resetNow: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      timeLeft: 60,
      displayTime: "60",
      timer: null
    };
  },
  methods: {
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          this.displayTime = this.timeLeft < 10 ? `0${this.timeLeft}` : this.timeLeft;
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.resetNow();
        }
      }, 1000);
    },
  },
  watch: {
    keyReload() {
      this.timeLeft = 60;
      this.displayTime = "60";
      clearInterval(this.timer);
      this.timer = null;
      this.startCountdown();
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: hidden !important;
}

.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
}

.modal-content {
  max-width: 300px;
}

.btn-close-modal {
  border: 1px solid #d3d3d3;
  padding: 5px 30px;
  text-align: center;
  border-radius: 10px;
  background: none;
}

.btn-continue-my-order,
.btn-reset-now {
  width: 100%;
  border: 1px solid #d3d3d3;
  padding: 15px 0;
  border-radius: 10px;
  font-weight: bold;
  line-height: normal !important;
  text-align: center;
  background: #ffffff;
  text-transform: uppercase;
}

.btn-continue-my-order {
  border: 1px solid #81ecec;
  color: #81ecec;
}
</style>
