<template>
  <div v-for="(modifierGroup, index) in itemInfo.modifier_group" :key="index">
    <div class="accordion pt-3 pb-3 pad-left-right">
      <div>
        <div class="d-flex justify-content-between align-items-center">
          <div style="display: flex; align-items: center">
            <div class="fs-16 font-weight-bold modifierGroupName">
              {{ modifierGroup.name }}
            </div>
            <div
              class="d-flex me-2"
              v-if="modifierGroup.name && modifierGroup.name.length <= 22"
            >
              <div class="fs-11 mt-1 ms-1">
                <span
                  class="font-inter"
                  v-if="modifierGroup.min_qty == 0 && modifierGroup.max_qty > 0"
                >
                  Select up to {{ modifierGroup.max_qty }}
                </span>
                <span
                  class="font-inter"
                  v-else-if="
                    modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1
                  "
                  :class="{
                    'select-min':
                      modifierGroup.min_qty > modifierGroup.total_selected,
                  }"
                >
                  Select 1
                </span>
                <span
                  class="font-inter"
                  v-else-if="
                    modifierGroup.min_qty == modifierGroup.max_qty &&
                    modifierGroup.max_qty > 1
                  "
                  :class="{
                    'select-min':
                      modifierGroup.min_qty > modifierGroup.total_selected,
                  }"
                >
                  Select {{ modifierGroup.max_qty }} ({{
                    modifierGroup.total_selected
                  }}/{{ modifierGroup.max_qty }})
                </span>
                <span
                  class="font-inter"
                  v-else
                  :class="{
                    'select-min':
                      modifierGroup.min_qty > modifierGroup.total_selected,
                  }"
                >
                  Select {{ modifierGroup.min_qty }} to
                  {{ modifierGroup.max_qty }}
                </span>
              </div>
            </div>
            <div
              v-if="
                modifierGroup.min_qty > 0 &&
                modifierGroup.total_selected >= modifierGroup.min_qty
              "
              class="icon-check ms-1"
            ></div>
          </div>
          <div
            class="d-inline-block signature-highlight modifierGroupSignature"
          >
            <div>
              {{ modifierGroup.min_qty == 0 ? "Optional" : "Compulsory" }}
            </div>
          </div>
        </div>
        <div
          class="d-flex"
          v-if="modifierGroup.name && modifierGroup.name.length > 22"
        >
          <div class="fs-11">
            <span
              class="font-inter"
              v-if="modifierGroup.min_qty == 0 && modifierGroup.max_qty > 0"
            >
              Select up to {{ modifierGroup.max_qty }}
            </span>
            <span
              class="font-inter"
              v-else-if="
                modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1
              "
              :class="{
                'select-min':
                  modifierGroup.min_qty > modifierGroup.total_selected,
              }"
            >
              Select 1
            </span>
            <span
              class="font-inter"
              v-else-if="
                modifierGroup.min_qty == modifierGroup.max_qty &&
                modifierGroup.max_qty > 1
              "
              :class="{
                'select-min':
                  modifierGroup.min_qty > modifierGroup.total_selected,
              }"
            >
              Select {{ modifierGroup.max_qty }} ({{
                modifierGroup.total_selected
              }}/{{ modifierGroup.max_qty }})
            </span>
            <span
              class="font-inter"
              v-else
              :class="{
                'select-min':
                  modifierGroup.min_qty > modifierGroup.total_selected,
              }"
            >
              Select {{ modifierGroup.min_qty }} to {{ modifierGroup.max_qty }}
            </span>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div
          class="option-item align-items-center"
          v-for="(item, index2) in modifierGroup.items"
          :key="index2"
          style="align-items: flex-start"
        >
          <div class="row">
            <div class="col-8">
              <div
                v-if="modifierGroup.min_qty == 1 && modifierGroup.max_qty == 1"
                class="d-flex align-items-center"
                :class="{ 'modifier-not-available': item.is_not_available }"
                @click="selectOnlyItem(indexChoice, index, index2, item)"
              >
                <MaterialRadio
                  :id="`item_${indexChoice}_${index}_${index2}`"
                  :name="`item_${indexChoice}_${index}_${index2}`"
                  class="ps-0 d-flex align-items-center option-item-radio"
                  :value="true"
                  v-model="item.selected"
                  :isDisabled="true"
                >
                </MaterialRadio>
                <div
                  class="ms-1"
                  :class="{
                    'font-inter-and-selected': item.selected,
                    'font-inter-and-not-selected': !item.selected,
                  }"
                >
                  <span>{{ item.item_name }} </span>
                  <span
                    v-if="item.is_not_available"
                    class="ms-2"
                    style="color: #eb2f06; font-size: 13px; width: 100%"
                    >NOT AVAILABLE</span
                  >
                </div>
              </div>
              <div
                v-else
                class="d-flex align-items-center"
                :class="{ 'modifier-not-available': item.is_not_available }"
                @click="selectMultiItem(indexChoice, index, index2, item)"
              >
                <material-checkbox
                  v-model="item.selected"
                  :checked="item.selected"
                  :id="`item_${indexChoice}_${index}_${index2}`"
                  :name="`item_${indexChoice}_${index}_${index2}`"
                  :isDisabled="
                    item.is_not_available ||
                    modifierGroup.max_qty == modifierGroup.total_selected
                      ? !modifierGroup.items_selected.includes(index2)
                      : false
                  "
                >
                </material-checkbox>
                <div
                  class="ms-1"
                  :class="{
                    'font-inter-and-selected': item.selected,
                    'font-inter-and-not-selected': !item.selected,
                    'disabled-item':
                      modifierGroup.max_qty == modifierGroup.total_selected
                        ? !modifierGroup.items_selected.includes(index2)
                        : false,
                  }"
                >
                  <span>{{ item.item_name }} </span>
                  <span
                    v-if="item.is_not_available"
                    class="ms-2"
                    style="color: #eb2f06; font-size: 13px; width: 100%"
                    >NOT AVAILABLE</span
                  >
                </div>
              </div>
            </div>
            <div
              class="col-4 d-flex align-items-center justify-content-end"
              style="display: grid !important"
            >
              <div
                v-if="!modifierGroup.is_remark && item.price > 0"
                style="text-align: right"
                :class="{
                  'font-inter-and-selected': item.selected,
                  'font-inter-and-not-selected': !item.selected,
                }"
              >
                +{{ item.price_default_format }}
              </div>
              <div
                class="d-flex align-items-center"
                v-if="
                  item.selected &&
                  item.item_max_qty > 1 &&
                  modifierGroup.max_qty > 1
                "
              >
                <i
                  class="material-icons-round opacity-10 cursor-pointer"
                  @click="removeModifierOptionGroup(indexChoice, index, index2)"
                  >remove</i
                >
                <div class="item-quantity">
                  {{ item.quantity }}
                </div>
                <i
                  class="material-icons-round opacity-10 cursor-pointer"
                  @click="addModifierOptionGroup(indexChoice, index, index2)"
                  >add</i
                >
              </div>
            </div>
          </div>
          <div
            v-if="item.is_show_modifier && item.item_in_mog && item.item_in_mog.id"
            class="mt-1 ms-3"
          >
            <div>Add modifier</div>
            <div
              v-for="(subModifierGroup, index3) in item.item_in_mog
                .modifier_group"
              :key="index3"
            >
              <div class="accordion pt-3 pb-3 pad-left-right">
                <div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div style="display: flex; align-items: center">
                      <div class="fs-16 font-weight-bold modifierGroupName">
                        {{ subModifierGroup.name }}
                      </div>
                      <div
                        class="d-flex me-2"
                        v-if="
                          subModifierGroup.name &&
                          subModifierGroup.name.length <= 22
                        "
                      >
                        <div class="fs-11 mt-1 ms-1">
                          <span
                            class="font-inter"
                            v-if="
                              subModifierGroup.min_qty == 0 &&
                              subModifierGroup.max_qty > 0
                            "
                          >
                            Select up to {{ subModifierGroup.max_qty }}
                          </span>
                          <span
                            class="font-inter"
                            v-else-if="
                              subModifierGroup.min_qty == 1 &&
                              subModifierGroup.max_qty == 1
                            "
                            :class="{
                              'select-min':
                                subModifierGroup.min_qty >
                                subModifierGroup.total_selected,
                            }"
                          >
                            Select 1
                          </span>
                          <span
                            class="font-inter"
                            v-else-if="
                              subModifierGroup.min_qty ==
                                subModifierGroup.max_qty &&
                              subModifierGroup.max_qty > 1
                            "
                            :class="{
                              'select-min':
                                subModifierGroup.min_qty >
                                subModifierGroup.total_selected,
                            }"
                          >
                            Select {{ subModifierGroup.max_qty }} ({{
                              subModifierGroup.total_selected
                            }}/{{ subModifierGroup.max_qty }})
                          </span>
                          <span
                            class="font-inter"
                            v-else
                            :class="{
                              'select-min':
                                subModifierGroup.min_qty >
                                subModifierGroup.total_selected,
                            }"
                          >
                            Select {{ subModifierGroup.min_qty }} to
                            {{ subModifierGroup.max_qty }}
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="
                          subModifierGroup.min_qty > 0 &&
                          subModifierGroup.total_selected >=
                            subModifierGroup.min_qty
                        "
                        class="icon-check ms-1"
                      ></div>
                    </div>
                    <div
                      class="d-inline-block signature-highlight modifierGroupSignature"
                    >
                      <div>
                        {{
                          subModifierGroup.min_qty == 0
                            ? "Optional"
                            : "Compulsory"
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex"
                    v-if="
                      subModifierGroup.name && subModifierGroup.name.length > 22
                    "
                  >
                    <div class="fs-11">
                      <span
                        class="font-inter"
                        v-if="
                          subModifierGroup.min_qty == 0 &&
                          subModifierGroup.max_qty > 0
                        "
                      >
                        Select up to {{ subModifierGroup.max_qty }}
                      </span>
                      <span
                        class="font-inter"
                        v-else-if="
                          subModifierGroup.min_qty == 1 &&
                          subModifierGroup.max_qty == 1
                        "
                        :class="{
                          'select-min':
                            subModifierGroup.min_qty >
                            subModifierGroup.total_selected,
                        }"
                      >
                        Select 1
                      </span>
                      <span
                        class="font-inter"
                        v-else-if="
                          subModifierGroup.min_qty ==
                            subModifierGroup.max_qty &&
                          subModifierGroup.max_qty > 1
                        "
                        :class="{
                          'select-min':
                            subModifierGroup.min_qty >
                            subModifierGroup.total_selected,
                        }"
                      >
                        Select {{ subModifierGroup.max_qty }} ({{
                          subModifierGroup.total_selected
                        }}/{{ subModifierGroup.max_qty }})
                      </span>
                      <span
                        class="font-inter"
                        v-else
                        :class="{
                          'select-min':
                            subModifierGroup.min_qty >
                            subModifierGroup.total_selected,
                        }"
                      >
                        Select {{ subModifierGroup.min_qty }} to
                        {{ subModifierGroup.max_qty }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <div
                    class="option-item align-items-center"
                    v-for="(subItem, index4) in subModifierGroup.items"
                    :key="index4"
                    style="align-items: flex-start"
                  >
                    <div class="row">
                      <div class="col-8">
                        <div
                          v-if="
                            subModifierGroup.min_qty == 1 &&
                            subModifierGroup.max_qty == 1
                          "
                          class="d-flex align-items-center"
                          :class="{
                            'modifier-not-available': item.is_not_available,
                          }"
                          @click="selectOnlyItem(indexChoice, index, index2, subItem, index3, index4)"
                        >
                          <MaterialRadio
                            :id="`item_${indexChoice}_${index}_${index2}_${index3}_${index4}`"
                            :name="`item_${indexChoice}_${index}_${index2}_${index3}_${index4}`"
                            class="ps-0 d-flex align-items-center option-item-radio"
                            :value="true"
                            v-model="subItem.selected"
                            :isDisabled="true"
                          >
                          </MaterialRadio>
                          <div
                            class="ms-1"
                            :class="{
                              'font-inter-and-selected': subItem.selected,
                              'font-inter-and-not-selected': !subItem.selected,
                            }"
                          >
                            <span>{{ subItem.item_name }} </span>
                            <span
                              v-if="subItem.is_not_available"
                              class="ms-2"
                              style="
                                color: #eb2f06;
                                font-size: 13px;
                                width: 100%;
                              "
                              >NOT AVAILABLE</span
                            >
                          </div>
                        </div>
                        <div
                          v-else
                          class="d-flex align-items-center"
                          :class="{
                            'modifier-not-available': subItem.is_not_available,
                          }"
                          @click="
                            selectMultiItem(indexChoice, index, index2, subItem, index3, index4)
                          "
                        >
                          <material-checkbox
                            v-model="subItem.selected"
                            :checked="subItem.selected"
                            :id="`item_${indexChoice}_${index}_${index2}_${index3}_${index4}`"
                            :name="`item_${indexChoice}_${index}_${index2}_${index3}_${index4}`"
                            :isDisabled="
                              subItem.is_not_available ||
                              subModifierGroup.max_qty ==
                                subModifierGroup.total_selected
                                ? !subModifierGroup.items_selected.includes(
                                    index4
                                  )
                                : false
                            "
                          >
                          </material-checkbox>
                          <div
                            class="ms-1"
                            :class="{
                              'font-inter-and-selected': subItem.selected,
                              'font-inter-and-not-selected': !subItem.selected,
                              'disabled-item':
                                subModifierGroup.max_qty ==
                                subModifierGroup.total_selected
                                  ? !subModifierGroup.items_selected.includes(
                                      index4
                                    )
                                  : false,
                            }"
                          >
                            <span>{{ subItem.item_name }} </span>
                            <span
                              v-if="subItem.is_not_available"
                              class="ms-2"
                              style="
                                color: #eb2f06;
                                font-size: 13px;
                                width: 100%;
                              "
                              >NOT AVAILABLE</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-4 d-flex align-items-center justify-content-end"
                        style="display: grid !important"
                      >
                        <div
                          v-if="!modifierGroup.is_remark && subItem.price > 0"
                          style="text-align: right"
                          :class="{
                            'font-inter-and-selected': subItem.selected,
                            'font-inter-and-not-selected': !subItem.selected,
                          }"
                        >
                          +{{ subItem.price_default_format }}
                        </div>
                        <div
                          class="d-flex align-items-center"
                          v-if="
                            subItem.selected &&
                            subItem.item_max_qty > 1 &&
                            subModifierGroup.max_qty > 1
                          "
                        >
                          <i
                            class="material-icons-round opacity-10 cursor-pointer"
                            @click="
                              removeModifierOptionGroup(
                                indexChoice,
                                index,
                                index2,
                                index3,
                                index4
                              )
                            "
                            >remove</i
                          >
                          <div class="item-quantity">
                            {{ subItem.quantity }}
                          </div>
                          <i
                            class="material-icons-round opacity-10 cursor-pointer"
                            @click="
                              addModifierOptionGroup(
                                indexChoice,
                                index,
                                index2,
                                index3,
                                index4
                              )
                            "
                            >add</i
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="break-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="break-line"></div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";

export default {
  name: "ItemModifier",
  components: {
    MaterialRadio,
    MaterialCheckbox,
  },
  props: {
    itemInfo: {
      type: Object,
      required: true,
    },
    selectOnlyItem: {
      type: Function,
      default: () => {},
    },
    selectMultiItem: {
      type: Function,
      default: () => {},
    },
    removeModifierOptionGroup: {
      type: Function,
      default: () => {},
    },
    addModifierOptionGroup: {
      type: Function,
      default: () => {},
    },
    indexChoice: {
      type: Number,
      default: -1,
    },
  },
};
</script>
