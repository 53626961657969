<template>
  <div class="container-custom" :style="styleObject">
    <header>
      <Header></Header>
    </header>
    <div class="content-custom" v-if="isLoadDone">
      <div class="content">
        <div
          v-if="
            isRequestTimeOut ||
            (message_error_from_megapos &&
              orderDetail &&
              !orderDetail.is_pushed_to_megapos_database)
          "
          class="mt-3"
        >
          <div class="d-flex flex-column align-items-center">
            <img
              src="@/assets/error.png"
              alt="error"
              style="width: 50%; height: auto"
            />
            <div class="font-weight-bold mt-3" style="color: red">
              Request timeout. Payment is unfinished. Please contact cashier for
              help.
            </div>
            <div
              class="font-weight-bold"
              style="color: red"
              v-if="message_error_from_megapos"
            >
              ERROR: {{ message_error_from_megapos }}
            </div>
          </div>
        </div>
        <div class="mt-3" v-else>
          <div>
            <div>
              {{ MERCHANT_BRAND_NAME ? MERCHANT_BRAND_NAME + " - " : "\xa0" }}
              {{ branch.branch_name }}
            </div>
            <div>ID: {{ transactionId }}</div>
          </div>
          <div class="d-flex align-items-center mt-3">
            <span
              class="material-icons me-3 color-green md-30"
              style="font-size: 40px"
              >check_circle</span
            >
            <div class="font-weight-bold">
              <div>Thank you</div>
              <div>Your order has been placed.</div>
            </div>
          </div>
          <div v-if="confettiActive" class="text-center mt-3">
            <span
              class="material-icons md-30"
              style="color: #2db550; font-size: 200px"
              >check_circle_outline</span
            >
          </div>
          <div
            class="mt-3 font-weight-bold d-flex align-items-center justify-content-center"
            style="font-size: 28px"
          >
            Order #{{ merchantCode.toLocaleUpperCase() }} -
            <span class="color-green">{{ orderDetail.order_number }}</span>
          </div>
          <div
            style="border: 1px solid #ececec; padding: 10px"
            v-if="orderDetail && !orderDetail.action_required"
            class="mt-3"
          >
            <div style="font-size: 18px">Order Updates</div>
            <div
              v-if="orderDetail"
              style="
                width: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                margin-top: 24px;
              "
            >
              <div
                style="
                  width: 80%;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  gap: 15px;
                "
              >
                <div style="position: relative">
                  <div
                    v-if="
                      status == 0 &&
                      orderDetail &&
                      orderDetail.is_pushed_to_megapos_database_real != 2 &&
                      orderDetail.is_pushed_to_megapos_database_real != 5
                    "
                    style="width: 22px; height: 20px"
                  >
                    <span class="material-icons color-green" style=""
                      >check_circle</span
                    >
                  </div>
                  <div
                    v-else
                    style="
                      width: 10px;
                      height: 10px;
                      background: gray;
                      border-radius: 50%;
                    "
                  ></div>
                  <div
                    :class="{ 'status-active': status == 0 }"
                    style="
                      position: absolute;
                      width: 200px;
                      text-align: center;
                      font-size: 14px;
                      left: calc(50% - 100px);
                      margin-top: 10px;
                    "
                  >
                    Payment Received
                  </div>
                </div>
                <div style="border-bottom: 1px solid gray; width: 200px"></div>
                <div style="position: relative">
                  <div v-if="status == 1" style="width: 22px; height: 20px">
                    <span class="material-icons color-green" style=""
                      >check_circle</span
                    >
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        orderDetail &&
                        orderDetail.is_pushed_to_megapos_database_real != 2 &&
                        orderDetail.is_pushed_to_megapos_database_real != 5
                      "
                      style="
                        width: 10px;
                        height: 10px;
                        background: gray;
                        border-radius: 50%;
                      "
                    ></div>
                    <div
                      v-else
                      style="
                        width: 10px;
                        height: 10px;
                        background: red;
                        border-radius: 50%;
                      "
                    >
                      <div class="circle-x"></div>
                    </div>
                  </div>
                  <div
                    :class="{ 'status-active': status == 1 }"
                    style="
                      position: absolute;
                      width: 200px;
                      text-align: center;
                      font-size: 14px;
                      left: calc(50% - 100px);
                      margin-top: 10px;
                    "
                  >
                    Preparing
                  </div>
                </div>
                <!-- <div style="border-bottom: 1px solid gray; width: 100px"></div> -->
                <!-- <div style="position: relative">
                  <div
                    v-if="
                      status == 2 &&
                      orderDetail &&
                      orderDetail.is_pushed_to_megapos_database_real != 2 &&
                      orderDetail &&
                      orderDetail.is_pushed_to_megapos_database_real != 5
                    "
                    style="width: 22px; height: 20px"
                  >
                    <span class="material-icons color-green" style=""
                      >check_circle</span
                    >
                  </div>
                  <div
                    v-else
                    style="
                      width: 10px;
                      height: 10px;
                      background: gray;
                      border-radius: 50%;
                    "
                  ></div>
                  <div
                    :class="{
                      'status-active':
                        status == 2 &&
                        orderDetail &&
                        orderDetail.is_pushed_to_megapos_database_real != 2 &&
                        orderDetail &&
                        orderDetail.is_pushed_to_megapos_database_real != 5,
                    }"
                    style="
                      position: absolute;
                      width: 80px;
                      text-align: center;
                      font-size: 14px;
                      left: calc(50% - 40px);
                      margin-top: 10px;
                    "
                  >
                    Ready
                  </div>
                </div> -->
              </div>
            </div>
            <div class="mt-5">
              <div style="font-size: 12px; color: gray" v-if="orderDetail">
                <div>
                  Paid $
                  {{ parseFloat(orderDetail.payment_amount).toFixed(2) }}
                  <span v-if="orderDetail.status != 0">
                    by
                    <span class="font-weight-bold">creditcard</span>
                  </span>
                </div>
                <div
                  class="d-flex align-items-center"
                  style="font-size: 12px; color: gray"
                >
                  Last updated: {{ latestUpdateTime }}
                  <span
                    class="material-icons ms-1"
                    style="font-size: 16px; color: #2db550"
                    >replay</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="orderDetail && !orderDetail.action_required" class="mt-3">
            <div
              class="div-order-items mt-0"
              v-for="(item, index) in itemSelect"
              :key="index"
              style="display: flex; align-items: center"
            >
              <div style="width: 100%; display: flex; align-items: flex-start">
                <div style="width: 10%; height: 40px">
                  <div
                    style="
                      text-align: center;
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      height: 100%;
                    "
                  >
                    {{ item.quantity }}
                  </div>
                </div>
                <div style="width: 3%"></div>
                <div style="width: 87%; display: flex; align-items: flex-start">
                  <div style="width: 20%; align-items: center; display: flex">
                    <img :src="item.image" style="width: 50px; height: 50px" />
                  </div>
                  <div
                    style="
                      width: 80%;
                      align-items: flex-start;
                      justify-content: center;
                      display: flex;
                    "
                  >
                    <div class="div-items" style="position: relative">
                      <div
                        class="item-name"
                        style="
                          width: 70%;
                          line-height: normal;
                          word-break: break-word;
                        "
                      >
                        <span
                          style="
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                          "
                          >{{ item.item_name }}</span
                        >
                        <div v-if="item.bundle_item.length">
                          <div
                            v-for="(
                              bundleItem, bundleIndex
                            ) in item.bundle_item"
                            :key="bundleIndex"
                          >
                            <div
                              style="display: table; width: 100%"
                              class="pad-left-right"
                              :style="{
                                marginTop: bundleIndex == 0 ? '10px' : '0px',
                              }"
                            >
                              <div
                                style="
                                  width: 20px;
                                  display: table-cell;
                                  vertical-align: top;
                                  padding: 4px;
                                  font-size: 12px;
                                  padding-left: 0px;
                                "
                              >
                                <div
                                  style="
                                    display: flex;
                                    display: flex;
                                    justify-content: end;
                                  "
                                >
                                  <div>
                                    <span style="font-size: 12px">{{
                                      bundleItem.quantity
                                    }}</span>
                                  </div>
                                  <div>&nbsp;x&nbsp;</div>
                                </div>
                              </div>
                              <div style="display: table-cell; width: auto">
                                <span style="font-size: 12px">{{
                                  bundleItem.itemName
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.choice && item.choice_selected">
                          <div
                            v-for="(itemChoice, indexChoice) in item
                              .choice_selected.items"
                            :key="indexChoice"
                          >
                            <div class="item-name" style="width: 100%">
                              {{
                                itemChoice.qty +
                                " x " +
                                itemChoice.item_detail.item_name
                              }}
                            </div>

                            <div
                              v-for="(
                                modifierOption, indexModifierOption
                              ) in itemChoice.item_detail.modifier_group"
                              :key="indexModifierOption"
                              class="ms-2"
                            >
                              <div
                                style="position: relative; display: grid"
                                class="div-items"
                                v-for="(
                                  itemModifierOption, indexItemModifierOption
                                ) in modifierOption.items"
                                :key="indexItemModifierOption"
                              >
                                <div
                                  class="div-items"
                                  v-for="(
                                    itemQuantity, indexQuantity
                                  ) in itemModifierOption.quantity"
                                  :key="indexQuantity"
                                >
                                  <div v-if="itemModifierOption.description">
                                    <div class="item-name" style="width: 100%">
                                      <span
                                        :style="{
                                          opacity:
                                            itemModifierOption.isCanGet == 1
                                              ? 0.3
                                              : 1,
                                        }"
                                        style="font-size: 12px"
                                        >{{
                                          itemModifierOption.description
                                        }}</span
                                      >
                                      <br /><span
                                        :style="{
                                          opacity:
                                            itemModifierOption.isCanGet == 1
                                              ? 0.3
                                              : 1,
                                        }"
                                        style="font-size: 12px"
                                        >{{
                                          itemModifierOption.item_name
                                        }}</span
                                      >
                                      <div
                                        v-if="itemModifierOption.isCanGet == 1"
                                        style="
                                          color: #124728;
                                          font-size: 12px;
                                        "
                                      >
                                        NO MORE AVAILABLE
                                      </div>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div class="item-name" style="width: 100%">
                                      <span
                                        :style="{
                                          opacity:
                                            itemModifierOption.isCanGet == 1
                                              ? 0.3
                                              : 1,
                                        }"
                                        style="font-size: 12px"
                                        >{{
                                          itemModifierOption.item_name
                                        }}</span
                                      >
                                      <div
                                        v-if="itemModifierOption.isCanGet == 1"
                                        style="
                                          color: #124728;
                                          font-size: 12px;
                                        "
                                      >
                                        NO MORE AVAILABLE
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="ms-2"
                                  v-if="itemModifierOption.item_in_mog"
                                >
                                  <div
                                    v-for="(
                                      subModifierOption, indexSubModifierOption
                                    ) in itemModifierOption.item_in_mog
                                      .modifier_group"
                                    :key="indexSubModifierOption"
                                  >
                                    <div
                                      style="position: relative; display: grid"
                                      class="div-items"
                                      v-for="(
                                        subItemModifierOption,
                                        indexSubItemModifierOption
                                      ) in subModifierOption.items"
                                      :key="indexSubItemModifierOption"
                                    >
                                      <div
                                        class="item-name"
                                        style="width: 100%"
                                      >
                                        <span style="font-size: 12px">{{
                                          subItemModifierOption.item_name
                                        }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-for="(
                              modifierOption, indexModifierOption
                            ) in item.modifier_group"
                            :key="indexModifierOption"
                            class="ms-2"
                          >
                            <div
                              style="position: relative; display: grid"
                              class="div-items"
                              v-for="(
                                itemModifierOption, indexItemModifierOption
                              ) in modifierOption.items"
                              :key="indexItemModifierOption"
                            >
                              <div
                                class="div-items"
                                v-for="(
                                  itemQuantity, indexQuantity
                                ) in itemModifierOption.quantity"
                                :key="indexQuantity"
                              >
                                <div v-if="itemModifierOption.description">
                                  <div class="item-name" style="width: 100%">
                                    <span
                                      :style="{
                                        opacity:
                                          itemModifierOption.isCanGet == 1
                                            ? 0.3
                                            : 1,
                                      }"
                                      style="font-size: 12px"
                                      >{{
                                        itemModifierOption.description
                                      }}</span
                                    >
                                    <br /><span
                                      :style="{
                                        opacity:
                                          itemModifierOption.isCanGet == 1
                                            ? 0.3
                                            : 1,
                                      }"
                                      style="font-size: 12px"
                                      >{{ itemModifierOption.item_name }}</span
                                    >
                                    <div
                                      v-if="itemModifierOption.isCanGet == 1"
                                      style="
                                        color: #124728;
                                        font-size: 12px;
                                      "
                                    >
                                      NO MORE AVAILABLE
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <div class="item-name" style="width: 100%">
                                    <span
                                      :style="{
                                        opacity:
                                          itemModifierOption.isCanGet == 1
                                            ? 0.3
                                            : 1,
                                      }"
                                      style="font-size: 12px"
                                      >{{ itemModifierOption.item_name }}</span
                                    >
                                    <div
                                      v-if="itemModifierOption.isCanGet == 1"
                                      style="
                                        color: #124728;
                                        font-size: 12px;
                                      "
                                    >
                                      NO MORE AVAILABLE
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="ms-2"
                                v-if="itemModifierOption.item_in_mog"
                              >
                                <div
                                  v-for="(
                                    subModifierOption, indexSubModifierOption
                                  ) in itemModifierOption.item_in_mog
                                    .modifier_group"
                                  :key="indexSubModifierOption"
                                >
                                  <div
                                    style="position: relative; display: grid"
                                    class="div-items"
                                    v-for="(
                                      subItemModifierOption,
                                      indexSubItemModifierOption
                                    ) in subModifierOption.items"
                                    :key="indexSubItemModifierOption"
                                  >
                                    <div class="item-name" style="width: 100%">
                                      <span style="font-size: 12px">{{
                                        subItemModifierOption.item_name
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="item-price-format"
                        style="
                          width: 30%;
                          opacity: 1;
                          align-items: flex-start;
                          display: flex;
                          justify-content: end;
                        "
                      >
                        <div style="display: grid">
                          <!-- <span
                            v-if="
                              !item.hasOwnProperty('none_member_price') ||
                              !item.none_member_price
                            "
                            style="
                              color: #bdbdbd;
                              text-decoration: line-through;
                              font-size: 12px;
                            "
                            >{{
                              getMainItem(
                                item,
                                item.is_member
                                  ? item.is_member
                                  : orderDetail && orderDetail.member_id > 0
                                  ? false
                                  : true
                              )
                            }}</span
                          > -->
                          {{
                            getMainItemPriceAndModifiersPrice(
                              item,
                              item.is_member
                                ? item.is_member
                                : orderDetail && orderDetail.member_id > 0
                                ? false
                                : true
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="width: 85%;display: flex;">
                    <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in item.OrderItems.slice(0, 2)" :key="index">
                        <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                        <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                    </div>
                </div>
                <div v-if="item.OrderItems && item.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                    <div style="margin-top: -50px;margin-left: 20px;">+{{ item.OrderItems.length - 2 }}</div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        v-if="
          isRequestTimeOut ||
          (message_error_from_megapos &&
            orderDetail &&
            !orderDetail.is_pushed_to_megapos_database)
        "
        class="d-flex justify-content-center"
      >
        <div class="w-50 me-3 btn-new-order" @click="retry()">
          Retry <span style="color: red">{{ "(" + seconds + ")" }}</span>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center">
        <div
          v-if="orderDetail && orderDetail.is_pushed_to_megapos_database"
          class="w-50 me-3 btn-new-order"
          @click="newOrder()"
        >
          New Order <span style="color: red">{{ "(" + seconds + ")" }}</span>
        </div>
        <div v-else style="color: red">Waiting POS confirm...</div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-3">
        Powered by
        <img
          src="@/assets/logo.jpg"
          alt=""
          class="ms-2"
          style="height: 20px; width: auto"
        />
      </div>
    </div>
    <div
      class="position-fixed top-1 end-1 message-success"
      style="z-index: 99999 !important"
    >
      <material-snackbar
        v-if="snackbarAsMember === 'danger' || snackbarAsMember === 'success'"
        title=""
        date=""
        :description="messageAsMember"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbarAsMember"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Header from "../kiosk/components/Header.vue";
import { localStorageName } from "@/config/utils";
import KioskService from "../../services/kiosk.service";
import confetti from "canvas-confetti";
import moment from "moment";

export default {
  name: "PaymentSuccess",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    Header,
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      merchantCode: "",
      branchCode: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
      MERCHANT_BRAND_NAME: localStorage.getItem(
        localStorageName("MERCHANT_BRAND_NAME"),
        ""
      ),
      isLogined: false,
      intervalCountdown: null,
      intervalOrderStatus: null,
      seconds: 20,
      transactionId: null,
      orderDetail: null,
      itemSelect: null,
      created_at: null,
      status: 0,
      confettiActive: false,
      isRequestTimeOut: false,
      timeoutId: null,
      latestUpdateTime: moment().format("DD/MM//YYYY hh:mm A"),
      message_error_from_megapos: "",
    };
  },
  async created() {
    this.showLoader();
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.transactionId = this.$route.params.transaction_id ?? null;

    if (!this.transactionId) {
      this.gotoPath("LandingPageKiosk");
    }

    await this.getOrderDetail();
    this.isLoadDone = true;

    if (this.memberInfo) {
      this.isLogined = true;
    }
  },
  mounted() {
    this.startPolling();
  },
  unmounted() {
    clearInterval(this.intervalOrderStatus);
    this.intervalOrderStatus = null;
    clearInterval(this.intervalCountdown);
    this.intervalCountdown = null;
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return "$ " + parseFloat(price).toFixed(2);
    },

    startCountdown() {
      if (this.intervalCountdown) return;

      this.intervalCountdown = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          clearInterval(this.intervalCountdown);
          this.intervalCountdown = null;
          this.gotoPath("LandingPageKiosk");
        }
      }, 1000);
    },

    async getOrderDetail() {
      this.latestUpdateTime = moment().format("DD/MM/YYYY hh:mm A");
      var dataForm = {
        branch_code: this.branchCode,
        transaction_id: this.transactionId,
      };

      await KioskService.getOrderDetail(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.orderDetail = response.data.data;
            this.itemSelect = response.data.data.items_ordering_data;

            if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 1
            ) {
              this.status = 1;
            } else if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 2
            ) {
              this.status = 2;
            } else {
              this.status = 0;
            }

            this.created_at = response.data.data.created_at;
            this.message_error_from_megapos = response.data.data
              .message_error_from_megapos
              ? response.data.data.message_error_from_megapos
              : "";

            if (
              this.message_error_from_megapos &&
              this.orderDetail &&
              !this.orderDetail.is_pushed_to_megapos_database
            ) {
              clearInterval(this.intervalCountdown);
              this.intervalCountdown = null;
              this.seconds = 30;
              this.startCountdown();
              clearInterval(this.intervalOrderStatus);
              this.intervalOrderStatus = null;
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.setTimeOutNotification();
        }
      );
    },

    getMainItemPriceAndModifiersPrice(item, isNonMember) {
      var priceMainAndModifer = 0;
      if (this.tableCode) {
        priceMainAndModifer =
          item.is_member && item.member_price_no_modifier_option
            ? item.member_price_no_modifier_option * item.quantity
            : item.price_default_no_modifier_option * item.quantity;
      } else {
        priceMainAndModifer =
          isNonMember == false && item.member_price_no_modifier_option
            ? item.member_price_no_modifier_option * item.quantity
            : item.price_default_no_modifier_option * item.quantity;
      }
      var totalPriceModifer = 0;

      if (item.choice) {
        item.choice_selected.items.forEach((itemChoice) => {
          itemChoice.item_detail.modifier_group.forEach((group) => {
            group.items.forEach((itemModifier) => {
              totalPriceModifer +=
                itemModifier.price_default *
                itemModifier.quantity *
                itemChoice.qty;

              if (
                itemModifier.item_in_mog &&
                itemModifier.item_in_mog.modifier_group
              ) {
                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                  subGroup.items.forEach((subItemModifier) => {
                    totalPriceModifer +=
                      subItemModifier.price_default *
                      subItemModifier.quantity *
                      itemModifier.quantity *
                      itemChoice.qty;
                  });
                });
              }
            });
          });
        });
      } else {
        item.modifier_group.forEach((group) => {
          group.items.forEach((itemModifier) => {
            totalPriceModifer +=
              itemModifier.price_default * itemModifier.quantity;

            if (
              itemModifier.item_in_mog &&
              itemModifier.item_in_mog.modifier_group
            ) {
              itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                subGroup.items.forEach((subItemModifier) => {
                  totalPriceModifer +=
                    subItemModifier.price_default *
                    subItemModifier.quantity *
                    itemModifier.quantity;
                });
              });
            }
          });
        });
      }

      return this.formatPrice(
        priceMainAndModifer + totalPriceModifer * item.quantity
      );
    },

    getMainItem(item, isNonMember) {
      var priceMainAndModifer = 0;
      if (this.tableCode) {
        isNonMember = !item.is_member;
      }
      priceMainAndModifer =
        isNonMember == false && item.member_price_no_modifier_option
          ? item.price_default_no_modifier_option * item.quantity
          : 0;
      var totalPriceModifer = 0;

      if (item.choice) {
        item.choice_selected.items.forEach((itemChoice) => {
          itemChoice.item_detail.modifier_group.forEach((group) => {
            group.items.forEach((itemModifier) => {
              totalPriceModifer +=
                itemModifier.price_default *
                itemModifier.quantity *
                itemChoice.qty;

              if (
                itemModifier.item_in_mog &&
                itemModifier.item_in_mog.modifier_group
              ) {
                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                  subGroup.items.forEach((subItemModifier) => {
                    totalPriceModifer +=
                      subItemModifier.price_default *
                      subItemModifier.quantity *
                      itemModifier.quantity *
                      itemChoice.qty;
                  });
                });
              }
            });
          });
        });
      } else {
        item.modifier_group.forEach((group) => {
          group.items.forEach((itemModifier) => {
            totalPriceModifer +=
              itemModifier.price_default * itemModifier.quantity;

            if (
              itemModifier.item_in_mog &&
              itemModifier.item_in_mog.modifier_group
            ) {
              itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                subGroup.items.forEach((subItemModifier) => {
                  totalPriceModifer +=
                    subItemModifier.price_default *
                    subItemModifier.quantity *
                    itemModifier.quantity;
                });
              });
            }
          });
        });
      }

      return priceMainAndModifer > 0
        ? this.formatPrice(
            priceMainAndModifer + totalPriceModifer * item.quantity
          )
        : "";
    },

    startPolling() {
      this.showLoader();
      this.intervalOrderStatus = setInterval(async () => {
        await this.getOrderDetail();

        if (
          !this.confettiActive &&
          this.orderDetail &&
          this.orderDetail.is_pushed_to_megapos_database
        ) {
          this.confettiActive = true;
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          });

          this.startCountdown();
          clearInterval(this.intervalOrderStatus);
          this.intervalOrderStatus = null;
          clearTimeout(this.timeoutId);
          this.hideLoader();
        }
      }, 3000);

      this.timeoutId = setTimeout(() => {
        clearInterval(this.intervalOrderStatus);
        this.intervalOrderStatus = null;
        clearInterval(this.intervalCountdown);
        this.intervalCountdown = null;
        clearTimeout(this.timeoutId);
        this.hideLoader();
        this.isRequestTimeOut = true;
        this.seconds = 30;
        this.startCountdown();
      }, 60000);
    },

    newOrder() {
      this.gotoPath("LandingPageKiosk");
    },

    retry() {
      this.showLoader();
      var dataForm = {
        branch_code: this.branchCode,
        transaction_id: this.transactionId,
      };

      KioskService.retryOrder(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.isRequestTimeOut = false;
            this.seconds = 20;
            clearInterval(this.intervalCountdown);
            this.intervalCountdown = null;
            this.message_error_from_megapos = "";
            this.orderDetail.is_pushed_to_megapos_database = null;
            this.startPolling();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    gotoPath(name) {
      this.$router.push({
        name: name,
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
        },
      });
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>

<style scoped>
.content {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 85px;
  overflow-y: auto;
  height: calc(100% - 180px);
}

.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-custom {
  flex: 1;
  background-color: white;
  overflow: auto;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 15px;
  padding-bottom: 5px;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%);
}

.btn-new-order {
  border: 1px solid #d3d3d3;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
}

.content::-webkit-scrollbar {
  display: none;
}
</style>
