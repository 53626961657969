<template>
  <footer class="footer">
    <div class="start-over me-3" @click="confirmStartOver()">START OVER</div>
    <div class="total-order">
      <div class="total-order">
        <img
          src="https://cdn-icons-png.freepik.com/512/5451/5451653.png"
          alt="logo"
          class="cart-img"
        />
        <div>$ {{ calculateTotal() }}</div>
        <div class="qty">{{ itemQuantity }}</div>
      </div>
    </div>
    <div class="view-cart ms-3" :class="{ 'disable': itemQuantity == 0}" @click="viewCart()">VIEW CART/CHECKOUT</div>
  </footer>
</template>

<script>
import EventBus from "vue3-eventbus";
// import { useRoute } from 'vue-router';
import { localStorageName } from "@/config/utils";

export default {
  name: "KioskOrderFooter",
  components: {},
  props: {
    confirmStartOver: {
      type: Function,
      default: () => {},
    }
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      itemQuantity: 0,
      itemSelect: [],
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
      isLogined: false,
      cartName: "ORDER_INFO",
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch("cartName");
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;

    let orderInfo = JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
    this.itemSelect = orderInfo;

    orderInfo.forEach((item) => {
      this.itemQuantity += item.quantity;
    });

    if (this.memberInfo) {
      this.isLogined = true;
    }
  },
  mounted() {
    EventBus.on("AddToCartSuccess", async () => {
      setTimeout(() => {
        this.itemQuantity = 0;
        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        this.itemSelect = orderInfo;
        orderInfo.forEach((item) => {
          this.itemQuantity += item.quantity;
        });
      }, 300);
    });
    EventBus.on("ChangeItemQuantitySuccess", async () => {
      setTimeout(() => {
        this.itemQuantity = 0;
        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        this.itemSelect = orderInfo;
        orderInfo.forEach((item) => {
          this.itemQuantity += item.quantity;
        });
      }, 300);
    });
  },
  methods: {
    calculateTotal() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        return this.itemSelect
          .reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0)
          .toFixed(2);
      } else {
        return "0.00";
      }
    },

    viewCart() {
      if (this.itemSelect && this.itemSelect.length) {
        this.$router.push({
          name: "CheckoutPageKiosk",
          params: {
            merchant_code: this.merchantCode,
            branch_code: this.branchCode,
          },
        });
      }
    },

    startOver() {
      localStorage.removeItem(this.cartName);

      this.$router.push({
        name: "LandingPageKiosk",
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
        },
      });
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%);
  padding: 0 30px 10px 30px;
}

.footer > div {
  width: calc(100% / 3);
}

.footer .start-over,
.footer .view-cart {
  border: 1px solid #d3d3d3;
  padding: 15px 0;
  border-radius: 10px;
  font-weight: bold;
  line-height: normal !important;
  text-align: center;
}

.footer .view-cart {
  background: #124728;
  color: #ffffff;
}

.footer .total-order {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer .total-order .cart-img {
  width: 75px;
  height: 75px;
}

.footer .total-order .qty {
  width: min-content;
  font-size: 11px;
  padding: 2px 8px;
  border: 1px solid red;
  border-radius: 50%;
  background: red;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .view-cart.disable {
  opacity: 0.5;
}
</style>
