<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-show-modal-signin"
      data-bs-toggle="modal"
      data-bs-target="#modalSignIn"
    ></button>
    <div
      class="modal fade"
      id="modalSignIn"
      tabindex="-1"
      aria-labelledby="modalSignInLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-end">
            <div class="btn-close-modal" data-bs-dismiss="modal">
              <div>&times;</div>
            </div>
          </div>
          <div class="modal-body">
            <div class="title">
              <div class="t-text">
                <div>Sign In and receive</div>
                <div>free food</div>
              </div>
              <div class="t-img mt-3">
                <img
                  src="https://beptueu.vn/hinhanh/tintuc/top-15-hinh-anh-mon-an-ngon-viet-nam-khien-ban-khong-the-roi-mat-1.jpg"
                  alt="error"
                  style="width: 100%; height: 150px"
                />
              </div>
            </div>
            <div class="content mt-3">
              <div class="member-type" v-if="step == 0">
                <div
                  id="nav_item_1"
                  class="nav-link"
                  :class="{ active: memberType === 1 }"
                  @click="changeMemberType(1)"
                >
                  Member
                </div>
                <div
                  id="nav_item_2"
                  class="nav-link"
                  @click="changeMemberType(2)"
                >
                  Non Member
                </div>
              </div>
              <div class="login-type" v-if="step == 1">
                <div
                  id="nav_item_1"
                  class="nav-link"
                  :class="{ active: loginType === 1 }"
                  @click="changeLoginType(1)"
                >
                  Via Mobile Phone
                </div>
                <div
                  id="nav_item_2"
                  class="nav-link"
                  @click="changeLoginType(2)"
                >
                  Scan QR-Code
                </div>
              </div>
            </div>
            <div class="phone-keypad mt-3" v-if="showFormLogin">
              <div class="input-text">
                {{
                  step == 1 ? "Enter your phone number" : "Enter your passcode"
                }}
              </div>
              <div class="display mt-1">
                {{ input }}
              </div>
              <div class="keys">
                <div v-for="key in keys" :key="key">
                  <button
                    @click="handleKeyClick(key)"
                    class="key"
                    :class="{ 'key-delete': key === 'X' || key === 'C' }"
                  >
                    {{ key }}
                  </button>
                </div>
              </div>
            </div>
            <div class="footer mt-5 d-flex justify-content-center">
              <material-button class="btn-skip" @click="skip()"
                >Skip</material-button
              >
              <material-button
                v-if="showFormLogin"
                class="btn-signin ms-3"
                @click="skip()"
                >Sign In</material-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import $ from "jquery";

export default {
  name: "ModalSignIn",
  components: {
    MaterialButton,
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      showFormLogin: false,
      keys: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "C", "0", "X"],
      input: "",
      memberType: 1,
      loginType: 1,
      step: 0,
    };
  },
  mounted() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
  },
  methods: {
    changeMemberType(memberType) {
      $(".member-type .nav-link").removeClass("active");
      $(".member-type #nav_item_" + memberType).addClass("active");
      this.memberType = memberType;
    },
    changeLoginType(loginType) {
      $(".login-type .nav-link").removeClass("active");
      $(".login-type #nav_item_" + loginType).addClass("active");
      this.loginType = loginType;
    },
    skip() {
      if (this.memberType == 1) {
        this.showFormLogin = true;
      } else {
        $(".btn-close-modal").click();

        this.$router.push({
          name: "OrderPageKiosk",
          params: {
            merchant_code: this.merchantCode,
            branch_code: this.branchCode,
          },
        });
      }

      this.step += 1;

      if (this.step == 3) {
        $(".btn-close-modal").click();

        this.$router.push({
          name: "OrderPageKiosk",
          params: {
            merchant_code: this.merchantCode,
            branch_code: this.branchCode,
          },
        });
      }
    },
    handleKeyClick(key) {
      if (key === "C") {
        this.input = "";
      } else if (key === "X") {
        this.input = this.input.slice(0, -1);
      } else {
        this.input += key;
      }
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: hidden;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.btn-close-modal {
  background-color: #fdcb6e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  z-index: 999999;
}

.btn-close-modal div {
  font-size: 28px !important;
  color: #cc8e35 !important;
  padding-right: 0 !important;
  margin-bottom: 4.5px;
}

.modal-content {
  background: #ffeaa7;
}

.title .t-text {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  font-family: "Noto Serif" !important;
}

.title .t-text > div:first-child {
  color: #cc8e35;
}

.title .t-text > div:last-child {
  color: #ff9f1a;
}

.member-type,
.login-type {
  display: flex;
  border: 1px solid red;
  border-radius: 50px;
}

.member-type > div,
.login-type > div {
  width: 50%;
  text-align: center;
  padding: 5px;
  margin: 2px;
  color: #ffffff;
  font-size: 12px;
  color: #cc8e35;
  font-weight: bold;
}

.member-type > div.active,
.login-type > div.active {
  background: brown;
  color: #ffffff;
  border: 1px solid red;
  border-radius: 50px;
}

.btn-skip,
.btn-signin {
  color: #cc8e35;
  border: 1px solid #cc8e35;
  border-radius: 50px;
  padding: 5px 50px;
  text-transform: unset !important;
}

.btn-skip {
  color: #cc8e35 !important;
  border: 1px solid #cc8e35 !important;
  border-radius: 50px;
  background: none;
}

.btn-skip:hover {
  background: none !important;
}

.btn-signin {
  color: #ffffff !important;
}

/* Phoen keypad */
.input-text {
  color: #cc8e35;
}

.phone-keypad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: auto;
}

.display {
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  line-height: 30px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 14px;
  background: #f9f9f9;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
}

.keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.key {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.key-delete {
  background: #cc8e35;
}

.key:hover {
  background-color: #e0e0e0;
}
</style>
