<template>
    <div class="container-custom" id="order-history-page" :style="styleObject" v-if="!loaderActivePayTopUp">
        <header>
            <div style="display: inline-flex; align-items: center;justify-content: space-between;width: 100%;background: var(--color);">
                <a style="width: 10%;height: 100%;display: flex; align-items: center; justify-content: center;">
                    <!-- <span class="material-icons color-green md-30" style="color: var(--color)!important;">arrow_back</span> -->
                </a>
                <div class="text-bold-500" style="width: 75%;align-items: center;justify-content: center;display: flex;font-weight:bold !important;">
                    Order History
                </div>
                <!-- <span class="material-icons color-green md-20">help</span> -->
                <a @click="openPopupReward" style="width: 15%;height: 48px;">
                    <!-- <img src="../assets/img/collect-reward.png" class="avatar avatar-md border-radius-lg" alt="user1" /> -->
                </a>
            </div>
            <!-- <div class="row d-flex justify-content-center" style="background: white;">
                <ul class="reward-tab">
                    <li
                    class="nav-item active"
                    id="nav_item_1"
                    @click="changeTab(1)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                    <strong>Ongoing</strong>
                    </li>
                    <li
                    class="nav-item"
                    id="nav_item_2"
                    @click="changeTab(2)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                    <strong>Completed</strong>
                    </li>
                </ul>
            </div>  -->
        </header>
        <div class="content-custom mt-3">
            <div style="height: 100%;">
                <div style="height: 100%;overflow: auto;">
                    <div class="wrap-card ps-1 pe-1" v-if="dataOrders.length">
                        <div v-if="typeChecked == 1">
                            <div v-for="(itemMain, index) in dataOrders" :key="index">
                                <div style="padding-left: 1em;padding-right: 1em;margin-top: 8px;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="color: black;">Order <span style="color: gray;">#{{ merchantCode + '-' +  itemMain.order_number}}</span></div>
                                        <div style="color: gray;font-size: 14px;">{{ dateFormat(itemMain.created_at) }}</div>
                                    </div>
                                    <div style="margin-top: 15px;width: 100%;height: auto;">
                                        <div class="div-order-items" v-for="(item, index) in itemMain.items_ordering_data" :key="index" style="display: flex;align-items: center;">
                                            <div style="width: 100%;display: flex;align-items: center;">
                                                <div style="width: 10%;height: 40px;">
                                                    <div style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                                        {{ item.quantity }}
                                                    </div>
                                                </div>
                                                <div style="width: 3%"></div>
                                                <div style="width: 87%;display: flex;border-bottom: 0.5px solid #D8DADA;padding-top: 10px;padding-bottom: 10px;">
                                                    <div style="width: 20%;align-items: center;display: flex;">
                                                        <img :src="item.image" style="width: 50px;height: 50px;"/>
                                                    </div>
                                                    <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                                        <div class="div-items" style="position: relative;">
                                                            <div class="item-name" style="width: 70%;line-height: normal;">
                                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                                                <div v-if="item.choice && item.choice_selected">
                                                                    <div v-for="(itemChoice, indexChoice) in item.choice_selected.items" :key="indexChoice">
                                                                        <div class="item-name" style="width:100%">
                                                                            {{ itemChoice.qty + ' x ' + itemChoice.item_detail.item_name }}
                                                                        </div>
                                                                        <div v-for="(modifierOption, indexModifierOption) in itemChoice.item_detail.modifier_group" :key="indexModifierOption" class="ms-2">
                                                                        <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                            <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                                <div class="item-name" style="width:100%">
                                                                                    <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                    <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="ms-2" v-if="itemModifierOption.item_in_mog">
                                                                                <div v-for="(subModifierOption, indexSubModifierOption) in itemModifierOption.item_in_mog.modifier_group" :key="indexSubModifierOption">
                                                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(subItemModifierOption, indexSubItemModifierOption) in subModifierOption.items" :key="indexSubItemModifierOption">
                                                                                        <div class="item-name" style="width:100%">
                                                                                            <span style="font-size: 12px;">{{ subItemModifierOption.item_name }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                        <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                            <div class="item-name" style="width:100%">
                                                                                <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="ms-2" v-if="itemModifierOption.item_in_mog">
                                                                            <div v-for="(subModifierOption, indexSubModifierOption) in itemModifierOption.item_in_mog.modifier_group" :key="indexSubModifierOption">
                                                                                <div style="position: relative;display: grid;" class="div-items" v-for="(subItemModifierOption, indexSubItemModifierOption) in subModifierOption.items" :key="indexSubItemModifierOption">
                                                                                    <div class="item-name" style="width:100%">
                                                                                        <span style="font-size: 12px;">{{ subItemModifierOption.item_name }}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                                                <div style="display: grid;">
                                                                    <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, itemMain.isNonMember) }}</span>
                                                                    {{ getMainItemPriceAndModifiersPrice(item, itemMain.isNonMember) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="width: 85%;display: flex;">
                                            <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in item.OrderItems.slice(0, 2)" :key="index">
                                                <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                                                <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                                            </div>
                                        </div>
                                        <div v-if="item.OrderItems && item.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                                            <div style="margin-top: -50px;margin-left: 20px;">+{{ item.OrderItems.length - 2 }}</div>
                                        </div> -->
                                    </div>
                                    <div style="width: 100%;margin-top: 10px;">
                                        <div style="color: black;text-align: right;display: flex;justify-content: end;gap: 10px;">
                                            <div v-if="isOrderPayLater">Seated</div>
                                            {{ countQuantityItems(itemMain) }} items
                                        </div>
                                        <div v-if="itemMain && itemMain.gst < 0"> <!-- inclusive -->
                                            <div v-if="itemMain && itemMain.service_charge_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div>{{ Math.abs(itemMain.service_charge) }}% Service Charge</div>
                                                <div>{{ formatPrice(itemMain.service_charge_amount) }}</div>
                                            </div>
                                            <div v-if="itemMain && itemMain.gst_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div>{{ Math.abs(itemMain.gst) }}% GST</div>
                                                <div>{{ formatPrice(itemMain.gst_amount) }}</div>
                                            </div>
                                            <div v-if="itemMain" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div style="">Total Incl. GST</div>
                                                <div style="">{{ formatPrice(parseFloat(itemMain.payment_amount) + parseFloat(itemMain.amount_direct_deduct)) }}</div>
                                            </div>
                                        </div>
                                        <div v-if="itemMain && itemMain.gst >= 0"> <!-- exclusive -->
                                            <!--<div v-if="itemMain && itemMain.benefits" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div v-if="itemMain.benefits.reward_type == 1">Cashback Earned</div>
                                                <div v-if="itemMain.benefits.reward_type == 2">Point Earned</div>
                                                <div v-if="itemMain.benefits.reward_type == 1">{{ formatPrice(itemMain.benefits.cashback_earn) }}</div>
                                                <div v-if="itemMain.benefits.reward_type == 2">{{ formatPrice(itemMain.benefits.point_earn) }}</div>
                                            </div>-->
                                            <div v-if="itemMain" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div>Subtotal</div>
                                                <div>{{ formatPrice(itemMain.total_order) }}</div>
                                            </div>
                                            <div v-if="itemMain && itemMain.service_charge_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div>{{ Math.abs(itemMain.service_charge) }}% Service Charge</div>
                                                <div>{{ formatPrice(itemMain.service_charge_amount) }}</div>
                                            </div>
                                            <div v-if="itemMain && itemMain.gst_amount > 0" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div>{{ Math.abs(itemMain.gst) }}% GST</div>
                                                <div>{{ formatPrice(itemMain.gst_amount) }}</div>
                                            </div>
                                            <div v-if="itemMain" style="display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                                                <div style="">TOTAL</div>
                                                <div style="">{{ formatPrice(parseFloat(itemMain.payment_amount) + parseFloat(itemMain.amount_direct_deduct)) }}</div>
                                            </div>
                                        </div>

                                        <!-- <div v-if="isOrderPayLater && accept_payment_on_moo" style="color: var(--color);" class="mt-1 w-100">
                                            <div @click="selectReward()" style="margin-top: 5px;color: #2DB550;font-size: 12px;display: flex;justify-content: left;align-items: center; cursor: pointer;">
                                                <span class="material-icons color-green md-30" style="font-size: 16px;margin-top: 0px;color:#2DB550 !important;margin-right: 3px;">discount</span>
                                                Use a voucher
                                            </div>
                                        </div> -->

                                        <!-- <div style="position: relative;margin-top: 10px;margin-bottom: 10px;width: 100%;border: 1px solid #bdbdbd;border-radius: 10px;" v-if="itemMain.voucher_code || rewardSelect.length > 0"> -->
                                        <div style="position: relative;margin-top: 10px;margin-bottom: 10px;width: 100%;border: 1px solid #bdbdbd;border-radius: 10px;" v-if="itemMain.voucher_code">
                                            <div style="width: 100%;padding: 10px;">
                                                <div style="display: flex;justify-content: space-between;align-items: center;gap: 10px;">
                                                    <div style="display: flex;align-items: center;gap: 5px;">
                                                        <span class="material-icons color-green md-30" style="font-size: 25px;color:#E10072 !important;">discount</span>
                                                        <div style="">{{ itemMain.discount_label }}</div>
                                                    </div>
                                                    <div style="color: #E10072;font-weight: 600;">
                                                        {{ formatPrice(itemMain.voucher_amount) }}
                                                    </div>
                                                </div>
                                                <div style="width: 100%;border-bottom: 1px dashed #bdbdbd;margin-top: 8px;margin-bottom: 8px;"></div>
                                                <div style="display: flex;justify-content: space-between;align-items: center;gap: 10px;">
                                                    <!-- <div v-if="checkEnableVoucher()" class="order-value-is-not"> -->
                                                    <div v-if="messageErrorVoucher" class="order-value-is-not">
                                                        <!-- Order value is not eligible to apply this voucher. -->
                                                        {{ messageErrorVoucher }}
                                                    </div>
                                                    <div v-else style="color: rgb(61, 131, 99);display: flex;align-items: center;gap: 5px;">
                                                        <span class="material-icons color-green md-30" style="font-size: 15px;color:#3D8363 !important;margin-top: 1px;">check_circle_outline</span>
                                                        <div style="color: #3D8363;">
                                                            Voucher applied!
                                                        </div>
                                                    </div>
                                                    <!-- <div style="color: #E10072;font-weight: 600; cursor: pointer;" @click="confirmRemoveReward()">
                                                        Remove
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div style="width: 12px;height: 12px;background-color: #FCFCFD;position: absolute;top: 46%;left: -8px;border-radius: 10px;border-right: 1px solid #bdbdbd;"></div>
                                            <div style="width: 12px;height: 12px;background-color: #FCFCFD;position: absolute;top: 46%;right: -8px;border-radius: 10px;border-left: 1px solid #bdbdbd;"></div>
                                        </div>

                                        <div v-if="isOrderPayLater && itemMain && itemMain.accept_payment_on_moo == 1 && itemMain && itemMain.is_pushed_to_megapos_database != 2" style="color:red">Disclaimer: If the amount shown is different that means the cashier may have amended the bill, given discount, voided or added items manually from POS. The items above are online orders only before any adjustment. If you are unsure please approach cashier to review before paying online.</div>
                                        
                                        <div class="card-body" style="margin-top: 10px;padding: 0px;" v-if="isOrderPayLater && itemMain && itemMain.accept_payment_on_moo == 1 && itemMain && itemMain.is_pushed_to_megapos_database != 2 && itemMain.action_required != 2">
                                            <div class="flex-container" style="margin-bottom: 10px;">
                                                <div class="inline-flex-container">
                                                    <div class="title-text" style="font-size: 15px">Payment Methods:</div>
                                                </div>
                                            </div>
                                            <div v-if="isLogined" class="flex-container" @click="selectInputMintyWallet">
                                                <div class="inline-flex-container">
                                                    <span class="material-icons color-green md-30" style="width: 30px;">monetization_on</span>
                                                    <div class="text-container">
                                                        E-Wallet (Balance: {{ memberInfo && memberInfo.amount_in_account_format ? memberInfo.amount_in_account_format : "$ 0.00" }}) <span v-if="disablePayWithMintyWallet" style="font-size:11px;color:red">insufficient</span>
                                                        <div v-if="memberInfo" style="color: #2EB34F;">Logged in as {{  memberInfo.name ? memberInfo.name : "" }} {{  memberInfo.last_name ? memberInfo.last_name : "" }}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="inline-flex-container gap-container">
                                                        <div class="mt-3 mb-3 inline-flex-container gap-container">
                                                            <input @click="selectInputMintyWallet" :disabled="disablePayWithMintyWallet" id="paymentMethodMintyWalletInput" v-model="selectPaymentMethod" value="1" class="radio-input" type="radio"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container" v-if="memberPermissions.includes('Top-up') && isLogined && memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(totalAmount)">
                                            <!-- <div class="flex-container" v-if="isCheckMerchantConnectedStripe === true && memberPermissions.includes('Top-up')"> -->
                                                <div style="width: 10%;"></div>
                                                <div style="width: 90%;">
                                                    <!-- <div class="margin-top-text">Balance {{ memberInfo && memberInfo.amount_in_account_format ? memberInfo.amount_in_account_format : "$ 0.00" }}</div> -->
                                                    <div class="not-enough-text">(Not enough to pay)</div>
                                                    <!-- <div v-if="memberInfo && Number(Number(memberInfo.amount_in_account).toFixed(2)) < Number(finalTotal())" class="inline-flex-container gap-container margin-top-text" style="height: auto;width: 60%; text-align: left;"> -->
                                                    <div v-for="(item, index) in topupList" :key="index" class="inline-flex-container gap-container margin-top-text" style="height: auto;width: 60%; text-align: left;">
                                                        <div @click="openModalConfirmTopup(item)" class="voucher-container">
                                                            <div class="inline-flex-container align-items-center">
                                                                <img src="../assets/img/my-cart/minty_wallet.png" alt="minty_wallet" class="wallet-icon"/>
                                                                <div class="white-bold-text">{{ item.topup_name }}</div>
                                                            </div>
                                                            <div class="padding-container">
                                                                <div class="voucher-text">Get S${{ Number(item.payment_amount).toFixed(2) }} {{ item.bonus_amount && item.bonus_amount > 0 ? " + S$"+Number(item.bonus_amount).toFixed(2) : "" }}</div>
                                                                <!-- <div class="voucher-text">Get $40 valued voucher set</div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-container" @click="selectInputStripe">
                                                <div class="inline-flex-container">
                                                    <div class="icon-container">
                                                        <span class="material-icons color-green md-30" style="width: 30px;">credit_card</span>
                                                    </div>
                                                    <div class="text-container">Debit/Creditcard</div>
                                                </div>
                                                <div>
                                                    <div class="mt-3 mb-3">
                                                        <input @click="selectInputStripe" id="paymentMethodStripeInput" v-model="selectPaymentMethod" value="2" class="radio-input" type="radio"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            v-if="isOrderPayLater && itemMain && itemMain.accept_payment_on_moo == 1 && itemMain && itemMain.is_pushed_to_megapos_database != 2 && itemMain.action_required != 2"
                                            @click="beforeHandlePayOnOrderHistory(itemMain.id)"
                                            class="btn bg-green text-center mb-0"
                                            type="button"
                                            style="
                                                color: var(--color) !important;
                                                background: transparent !important;
                                                border-radius: 10px !important;
                                                border: 1px solid var(--color) !important;
                                                width: 100%;
                                                padding: 10px 10px;
                                                font-size: 17px;
                                                font-weight: normal;
                                                text-transform: none;
                                                margin-top: 10px
                                            "
                                            :disabled='waitingMegaposResponse || payload'
                                        >
                                            {{ waitingMegaposResponse ? 'Loading...' : 'Pay ' + formatPrice(itemMain.total_amount_from_megapos) }}
                                        </button>
                                    </div>
                                    <!-- <div v-if="tableCode" style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="">shopping_basket</span> -->
                                            <!-- <div style="color: black;font-weight:bold;" v-if="itemMain.status && itemMain.status == 2">
                                                Ordering
                                            </div> -->
                                            <!-- <div style="color: black;font-weight:bold;" v-else-if="itemMain.is_pushed_to_megapos_database && itemMain.is_pushed_to_megapos_database == 2">
                                                Ready for collection
                                            </div> -->
                                            <!-- <div style="color: black;font-weight:bold;" v-else-if="itemMain.status && itemMain.status == 3">
                                                Seated
                                            </div> -->
                                            <!-- <div style="color: black;font-weight:bold;">
                                                Seated
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div> -->
                                    <div v-if="!isOrderPayLater" style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="">shopping_basket</span>
                                            <div style="color: black;font-weight:bold;" v-if="itemMain.is_pushed_to_megapos_database && itemMain.is_pushed_to_megapos_database == 1">
                                                Preparing
                                            </div>
                                            <div style="color: black;font-weight:bold;" v-else>
                                                Confirming
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div>
                                    <!-- <div style="width:100%;border-bottom: 0.5px solid lightgray;margin-top: 10px"></div> -->
                                    <div v-if="!isOrderPayLater"  style="width:100%;margin-top:10px;justify-content: start;display: flex;">
                                        <div style="display: flex;gap: 10px;">
                                            <div @click="viewDetailOrder(itemMain.transaction_id)" style="width: 130px;text-align:center;font-weight:normal;border: 1px solid lightgray;border-radius: 10px;color: black;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">View</div>
                                            <!-- <div style="width: 130px;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 10px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">Re-order</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;border-bottom: 10px solid lightgray;margin-top: 10px;"></div>
                            </div>
                            <div v-if="totalRealOrder > 5" style="width: 100%;align-items: center;display: flex;justify-content: center;">
                                <div @click="loadMore(1)" style="width: 50%;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 30px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;margin-top: 20px;margin-bottom: 20px;">
                                    Load more
                                </div>
                            </div>
                        </div>
                        <div v-if="typeChecked == 2">
                            <div v-for="(itemMain, index) in dataOrders" :key="index">
                                <div style="padding-left: 1em;padding-right: 1em;margin-top: 8px;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="color: black;">Order <span style="color: gray;">#{{ itemMain.receipt_no }}</span></div>
                                        <div style="color: gray;font-size: 14px;">{{ dateFormat(itemMain.created_at) }}</div>
                                    </div>
                                    <div style="margin-top: 15px;width: 100%;height: auto;">
                                        <div class="div-order-items" v-for="(item, index) in itemMain.items_ordering_data" :key="index" style="display: flex;align-items: center;">
                                            <div style="width: 100%;display: flex;align-items: center;">
                                                <div style="width: 10%;height: 40px;">
                                                    <div style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                                        {{ item.quantity }}
                                                    </div>
                                                </div>
                                                <div style="width: 3%"></div>
                                                <div style="width: 87%;display: flex;border-bottom: 0.5px solid #D8DADA;padding-top: 10px;padding-bottom: 10px;">
                                                    <div style="width: 20%;align-items: center;display: flex;">
                                                        <img :src="item.image" style="width: 50px;height: 50px;"/>
                                                    </div>
                                                    <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                                        <div class="div-items" style="position: relative;">
                                                            <div class="item-name" style="width: 70%;line-height: normal;">
                                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                                                <div v-if="item.choice && item.choice_selected">
                                                                    <div v-for="(itemChoice, indexChoice) in item.choice_selected.items" :key="indexChoice">
                                                                        <div class="item-name" style="width:100%">
                                                                            {{ itemChoice.qty + ' x ' + itemChoice.item_detail.item_name }}
                                                                        </div>
                                                                        <div v-for="(modifierOption, indexModifierOption) in itemChoice.item_detail.modifier_group" :key="indexModifierOption" class="ms-2">
                                                                        <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                            <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                                <div class="item-name" style="width:100%">
                                                                                    <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                    <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                        <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                            <div class="item-name" style="width:100%">
                                                                                <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                                                <div style="display: grid;">
                                                                    <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, itemMain.isNonMember) }}</span>
                                                                    {{ getMainItemPriceAndModifiersPrice(item, itemMain.isNonMember) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="width: 85%;display: flex;">
                                            <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in itemMain.OrderItems.slice(0, 2)" :key="index">
                                                <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                                                <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                                            </div>
                                        </div>
                                        <div v-if="itemMain.OrderItems && itemMain.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                                            <div style="margin-top: -50px;margin-left: 20px;">+{{ itemMain.OrderItems.length - 2 }}</div>
                                        </div> -->
                                    </div>
                                    <div style="width: 100%;margin-top: 10px;">
                                        <div style="color:black;text-align: right;">
                                            {{ countQuantityItems(itemMain) }} items
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="color: green !important;">check_circle</span>
                                            <div style="color: black;font-weight:bold;">
                                                Completed
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div>
                                    <!-- <div style="width:100%;border-bottom: 0.5px solid lightgray;margin-top: 10px"></div> -->
                                    <div v-if="!isOrderPayLater" style="width:100%;margin-top:10px;justify-content: start;display: flex;">
                                        <div style="display: flex;gap: 10px;">
                                            <div @click="viewDetailOrder(itemMain.transaction_id)" style="width: 130px;text-align:center;font-weight:normal;border: 1px solid lightgray;border-radius: 10px;color: black;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">View</div>
                                            <!-- <div style="width: 130px;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 10px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">Re-order</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;border-bottom: 10px solid lightgray;margin-top: 10px;"></div>
                            </div>
                            <div v-if="totalRealOrder > 5" style="width: 100%;align-items: center;display: flex;justify-content: center;">
                                <div @click="loadMore(2)" style="width: 50%;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 30px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;margin-top: 20px;margin-bottom: 20px;">
                                    Load more
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center p-5" style="background-color: #f0f2f5;"> {{ typeChecked == 1 ? "You don't have any ongoing order" : "No order history available" }} </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <Footer></Footer>
            <VoucherAvailable></VoucherAvailable>
        </footer>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-timeout-order-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalTimeOutOrder"
            ></button>
            <div
                class="modal fade"
                id="modalTimeOutOrder"
                tabindex="-1"
                aria-labelledby="modalTimeOutOrder"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div>Transaction is timeout!</div>
                            <!-- <div>Please contact staff</div> -->
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-close-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Close
                            </button>
                            <button
                                @click="handlePayOnOrderHistory(1)"
                                type="button"
                                class="btn btn-sm"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Retry
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-total-amount-order-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalTotalAmount"
            ></button>
            <div
                class="modal fade"
                id="modalTotalAmount"
                tabindex="-1"
                aria-labelledby="modalTotalAmount"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div>{{messsageErrorRequestActionTotalAmount}}</div>
                            <!-- <div>Please contact staff</div> -->
                        </div>
                        <div class="modal-footer">
                            <button
                                @click="closeModal()"
                                type="button"
                                class="btn btn-close-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-paid-order-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalPaidOrder"
            ></button>
            <div
                class="modal fade"
                id="modalPaidOrder"
                tabindex="-1"
                aria-labelledby="modalPaidOrder"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div>{{messsageErrorRequestActionPaidOrder}}</div>
                            <!-- <div>Please contact staff</div> -->
                        </div>
                        <div class="modal-footer">
                            <button
                                @click="closeModal()"
                                type="button"
                                class="btn btn-close-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Close
                            </button>
                            <button
                                @click="backToHome()"
                                type="button"
                                class="btn btn-sm"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Back to Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button
                style="display: none"
                type="button"
                class="btn-confirm-topup-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmTopup"
            ></button>
            <div
                class="modal fade"
                id="modalConfirmTopup"
                tabindex="-1"
                aria-labelledby="modalConfirmTopup"
                aria-hidden="true"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header" style="">
                            TOP UP MINTY WALLET
                        </div>
                        <div class="modal-body">
                            <div v-if="topupCurrentlyHandle">Top Up Amount: S${{ Number(topupCurrentlyHandle.topup_amount).toFixed(2) }}</div>
                            <div v-if="topupCurrentlyHandle && topupCurrentlyHandle.bonus_amount && topupCurrentlyHandle.bonus_amount > 0">Bonus Amount: S${{ Number(topupCurrentlyHandle.bonus_amount).toFixed(2) }}</div>
                            <div v-if="topupCurrentlyHandle">Payment Amount: S${{ Number(topupCurrentlyHandle.payment_amount).toFixed(2) }}</div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-close-confirm-topup-modal btn-sm"
                                data-bs-dismiss="modal"
                                style="color: white !important;background: gray !important;border-radius: 10px !important;"
                            >
                                Cancel
                            </button>
                            <button
                                @click="processPaymentTopUp()"
                                type="button"
                                class="btn btn-sm"
                                style="color: white !important;background: var(--color) !important;border-radius: 10px !important;"
                            >
                                Pay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2">
        <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
        />
    </div>
    <material-loading :active="loaderActive || loaderActivePayTopUp" />
    <ModalNotificationSignIn :message="'Please login to use voucher!'"/>
    <ModalConfirm
        message="Are you sure you want to remove voucher?"
        :process-yes="removeReward"
      />
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import $ from "jquery";
  import Footer from "@/common/Footer/OrderFooter";
  import OrderService from "../services/order.service";
  import MemberService from "../services/member.service";
  import AuthService from "../services/auth.service";
  import moment from 'moment';
  import { localStorageName } from '@/config/utils';
  import ModalNotificationSignIn from "@/components/ModalNotificationSignIn.vue";
  import ModalConfirm from "@/components/ModalConfirm.vue";
  
  export default {
    name: "my-profile",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Footer,
      ModalNotificationSignIn,
      ModalConfirm
    },
    data() {
      return {
        snackbar: null,
        loaderActive: false,
        typeChecked: 1,
        memberId: 0,
        valueFromParent: 'default_value',
        dataOrders: [],
        totalRealOrder: 0,
        page: 0,
        memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
        merchantCode: "",
        branchCode: "",
        pathType: "",
        tableCode: "",
        tableInfo: null,
        isOrderPayLater: false,
        date_time_request_action_required_to_megapos: null,
        intervalId: null,
        timeoutId: null,
        idIsRequestActionRequired: null,
        messsageErrorRequestActionTotalAmount: "",
        messsageErrorRequestActionPaidOrder: "",
        disablePayWithMintyWallet: false,
        selectPaymentMethod: localStorage.getItem(localStorageName('PAYMENT_METHOD'), 1),
        isCheckMerchantConnectedStripe: false,
        topupList: [],
        totalAmount: 0,
        topupCurrentlyHandle: null,
        memberPermissions: [],
        loaderActivePayTopUp: false,
        isLogined: false,
        voucher_deducted_format: "",
        //rewardSelect: JSON.parse(localStorage.getItem(localStorageName('SELECTED_REWARD_FROM_ORDER_HISTORY'), null)) ?? [],
        is_remove_voucher: false,
        waitingMegaposResponse: false,
        accept_payment_on_moo: true,
        payLoad: false
      };
    },
    async created() {
        this.merchantCode = this.$store.state.merchantCode;
        this.branchCode = this.$store.state.branchCode;
        this.pathType = this.$route.params.type;
        this.tableCode = this.$route.params.table_code;
        this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), null));

        //load after payment done
        var session_id = this.$route.query.session_id;
        var price = this.$route.query.price;
        var id = this.$route.query.id;

        if(session_id) { // call back after pay success - stripe
            this.loaderActivePayTopUp = true;
            MemberService.addDirectAmountAfterPayWithCard(session_id, price, id, this.merchantCode).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        var url = location.protocol + '//' + location.host + "/" + this.merchantCode;
                        if (this.tableInfo) {
                            url += "/QR/"+this.branchCode+"/"+this.tableCode+"/order-history";
                        } else {
                            url += "/"+this.branchCode+"/order-history";
                        }

                        window.location.href = url;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                        this.loaderActivePayTopUp = false;
                    }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                    this.loaderActivePayTopUp = false;
                }
            );
        } else {
            if (this.tableInfo) {
                this.accept_payment_on_moo = this.tableInfo.accept_payment_on_moo ?? false;

                if (this.tableInfo.stripe_connect) {
                    this.isOrderPayLater = false;
                } else {
                    this.isOrderPayLater = true;
                }
            } else {
                this.isOrderPayLater = false;
            }

            if(this.memberInfo) {
                this.memberId = this.memberInfo.id ?? 0;
                localStorage.setItem(localStorageName('PAYMENT_METHOD'), 1);
                this.selectPaymentMethod = 1;
                this.isLogined = true;
            } else {
                localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
                this.selectPaymentMethod = 2;
            }

            await this.getMemberInfo();
            this.changeTab(1);
            this.getTopupList();
            this.checkMemberPermissions();

            // if (this.rewardSelect.length) {
                // this.waitingMegaposResponse = true;
                // $('#order-history-page').addClass('no-clicks');
                // this.intervalId = setInterval(() => {
                    // this.getOrdersByTable(this.typeChecked);
                // }, 1000);
            // }
        }

    },
    computed: {
        styleObject() {
            return {
                "--color": this.$store.state.color,
            };
        },
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
        changeTab(type) {
            this.dataOrders = [];
            if(type == 2) {
                this.valueFromParent = Math.random();
                setTimeout(() => {
                    $(".nav-item").removeClass("active");
                    $("#nav_item_" + type).addClass("active");
                    this.typeChecked = type;
                }, 500);
            } else {
                $(".nav-item").removeClass("active");
                $("#nav_item_" + type).addClass("active");
                this.typeChecked = type;
            }

            if (this.isOrderPayLater) {
                this.getOrdersByTable(type);
            } else {
                if (this.memberInfo) {
                    this.getOrdersByMember(type);
                } else {
                    this.getOrdersByNonMember(type);
                }
            }
        },
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        goBack() {
            this.$router.go(-1);
        },
        loadMore(type) {
            if (this.memberInfo) {
                this.getOrdersByMember(type);
            } else {
                this.getOrdersByNonMember(type);
            }
        },
        getOrdersByMember(type) {
            this.showLoader();
            const currentPage = Math.ceil(this.dataOrders.length / (this.page === 0 ? 5 : 3));
            const perPage = this.page === 0 ? 5 : 3;

            OrderService.getOrdersByMember(this.branchCode, type == 1 ? "PAID" : "ACCEPTED", currentPage, perPage).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.page = response.data.data.page;
                        this.dataOrders = this.page === 0 ? response.data.data.data : this.dataOrders.concat(response.data.data.data);
                        if(response.data.data.data && response.data.data.data.length < 3) {
                            this.totalRealOrder = 0;
                        } else {
                            this.totalRealOrder = response.data.data.total;
                        }
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                }
            );
        },
        getOrdersByNonMember(type) {
            this.showLoader();
            const ordersId = JSON.parse(localStorage.getItem(localStorageName('ORDER_ID_NON_MEMBER'), []));
            const currentPage = Math.ceil(this.dataOrders.length / (this.page === 0 ? 5 : 3));

            var dataForm = {
                branch_code: this.branchCode,
                status: type == 1 ? "PAID" : "ACCEPTED",
                orders_id: ordersId,
                current_page: currentPage
            }

            OrderService.getOrdersByNonMember(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.page = response.data.data.page;
                    this.dataOrders = this.page === 0 ? response.data.data.data : this.dataOrders.concat(response.data.data.data);
                    if(response.data.data.data && response.data.data.data.length < 3) {
                        this.totalRealOrder = 0;
                    } else {
                        this.totalRealOrder = response.data.data.total;
                    }
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                }
            );
        },
        async getOrdersByTable(type) {
            this.showLoader();
            var dataForm = {
                branch_code: this.branchCode,
                table_code: this.tableCode,
                status: type == 1 ? "PAID" : "ACCEPTED",
                member_id: this.memberInfo? this.memberInfo.id : 0,
                //promocode: this.rewardSelect,
                //is_remove_voucher: this.isRemoveVoucher
            }

            await OrderService.getOrdersByTable(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.dataOrders = response.data.data;
                        this.totalRealOrder = 0;
                        //this.isRemoveVoucher = false;
                        //this.rewardSelect = [];
                        localStorage.removeItem(localStorageName('SELECTED_REWARD_FROM_ORDER_HISTORY'));
                        this.hanldeSelectPaymentMethod();

                        if (this.dataOrders.length) {
                            if (this.dataOrders[0].waiting_megapos_response) {
                                this.waitingMegaposResponse = true;
                                $('#order-history-page').addClass('no-clicks');
                                this.intervalId = setInterval(() => {
                                    this.getOrdersByTable(this.typeChecked);
                                }, 1000);
                            } else {
                                this.waitingMegaposResponse = false;
                                $('#order-history-page').removeClass('no-clicks');
                                clearInterval(this.intervalId);
                            }
                        }
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                }
            );
        },
        dateFormat(date) {
            if (date == null) {
                return null;
            } else {
                return moment(date).format("YYYY-MM-DD HH:mm");
            }
        },
        viewDetailOrder(order_id) {
            this.$router.push({ name: "PaymentSuccess", params: {
                merchant_code: this.merchantCode,
                type: this.pathType,
                branch_code: this.branchCode,
                table_code: this.tableCode,
                order_id: order_id
            } });
        },
        getMainItemPriceAndModifiersPrice(item, isNonMember) {
            var priceMainAndModifer = 0;
            if (this.tableCode) {
                priceMainAndModifer = item.is_member && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
            } else {
                priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
            }
            var totalPriceModifer = 0;

            if (item.choice) {
                item.choice_selected.items.forEach((itemChoice) => {
                    itemChoice.item_detail.modifier_group.forEach((group) => {
                        group.items.forEach((itemModifier) => {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity * itemChoice.qty;

                            if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                    subGroup.items.forEach((subItemModifier) => {
                                        totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity * itemChoice.qty;
                                    });
                                })
                            }
                        });
                    });
                })
            } else {
                item.modifier_group.forEach((group) => {
                    group.items.forEach((itemModifier) => {
                        totalPriceModifer += itemModifier.price_default * itemModifier.quantity;

                        if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                            itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                subGroup.items.forEach((subItemModifier) => {
                                    totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity;
                                });
                            })
                        }
                    });
                });
            }

            return this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity));
        },
        getMainItem(item, isNonMember) {
            var priceMainAndModifer = 0;
            if (this.tableCode) {
                isNonMember = !item.is_member;
            }
            priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.price_default_no_modifier_option * item.quantity : 0;
            var totalPriceModifer = 0;

            if (item.choice) {
                item.choice_selected.items.forEach((itemChoice) => {
                    itemChoice.item_detail.modifier_group.forEach((group) => {
                        group.items.forEach((itemModifier) => {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity * itemChoice.qty;

                            if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                                itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                    subGroup.items.forEach((subItemModifier) => {
                                        totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity * itemChoice.qty;
                                    });
                                })
                            }
                        });
                    });
                })
            } else {
                item.modifier_group.forEach((group) => {
                    group.items.forEach((itemModifier) => {
                        totalPriceModifer += itemModifier.price_default * itemModifier.quantity;

                        if (itemModifier.item_in_mog && itemModifier.item_in_mog.modifier_group) {
                            itemModifier.item_in_mog.modifier_group.forEach((subGroup) => {
                                subGroup.items.forEach((subItemModifier) => {
                                    totalPriceModifer += subItemModifier.price_default * subItemModifier.quantity * itemModifier.quantity;
                                });
                            })
                        }
                    });
                });
            }
            
            return priceMainAndModifer > 0 ? this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity)) : "";
        },
        formatPrice(price) {
            return 'S$ ' + parseFloat(price).toFixed(2);
        },
        countQuantityItems(item) {
            var totalQuantityItems = 0;

            item.items_ordering_data.forEach((mainItem) => {
                totalQuantityItems += mainItem.quantity;
                // mainItem.modifier_group.forEach((group) => {
                //     group.items.forEach((itemModifier) => {
                //         totalQuantityItems += itemModifier.quantity;
                //     });
                // });
            });

            return totalQuantityItems;
        },
        beforeHandlePayOnOrderHistory(id) {
            this.payload = true;
            this.idIsRequestActionRequired = id;
            this.handlePayOnOrderHistory(1);
        },
        handlePayOnOrderHistory(action_required_param) {
            this.messsageErrorRequestActionTotalAmount = "";
            this.messsageErrorRequestActionPaidOrder = "";
            // $(".btn-close-modal").click();
            this.showLoader();
            var dataForm = {
                minty_transaction_id: this.idIsRequestActionRequired,
                branch_code: this.branchCode,
                table_code: this.tableCode,
                action_required: action_required_param
            }

            OrderService.requestActionRequired(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.date_time_request_action_required_to_megapos = response.data.data.date_time_request_action_required_to_megapos;
                        this.startPolling(response.data.data.id);
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                        this.payload = false;
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.payload = false;
                }
            );
        },
        processPaymentStripe(transaction_id, amount, minty_transaction_id) {
            this.showLoader();
            const merchantCode = this.$store.state.merchantCode;
            const branchCode = this.$store.state.branchCode;
            var urlCancel = location.protocol + '//' + location.host+"/"+merchantCode;
            var url = location.protocol + '//' + location.host+"/"+merchantCode;

            if (this.tableInfo) {
                url += "/QR/" + branchCode + "/" + this.tableCode + "/payment-success/" + transaction_id;
                urlCancel += "/QR/" + branchCode + "/" + this.tableCode + "/my-cart";
            } else {
                url += "/" + branchCode + "/payment-success/" + transaction_id;
                urlCancel += "/" + branchCode + "/my-cart";
            }

            var dataSend = {
                success_url: url+"?session_id={CHECKOUT_SESSION_ID}&transaction_id="+transaction_id+"&price="+amount+"&isPaylaterOnMobile=1&minty_transaction_id="+minty_transaction_id,
                cancel_url: urlCancel,
                images: "",
                name: "Order Items",
                unit_amount: amount,
                quantity: 1,
                branch_code: this.branchCode,
                transaction_id: transaction_id,
                member_id: this.memberId,
                merchant_id: localStorage.getItem(localStorageName('MERCHANT_ID'), 0),
                isPaymentItem: 2
            };
            OrderService.checkoutSessionStripe(dataSend).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        window.location.href = response.data.data.url
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                    this.hideLoader();
                }
            );
        },
        getOrderByOrderId(id) {
            this.showLoader();
            var dataForm = {
                minty_transaction_id: id,
                branch_code: this.branchCode,
                table_code: this.tableCode
            };

            OrderService.getOrderByOrderIdForActionRequired(dataForm).then(
            (response) => {
                if (response.data.result.isSuccess) {
                    if(response.data.data.date_time_request_action_required_to_megapos !== this.date_time_request_action_required_to_megapos) {
                        clearInterval(this.intervalId);
                        clearTimeout(this.timeoutId);
                        if(response.data.data.message_after_request_action_required != "" && response.data.data.message_after_request_action_required == "Online payment is not available. Please make payment at the counter.") {
                            this.messsageErrorRequestActionTotalAmount = response.data.data.message_after_request_action_required;
                            $(".btn-total-amount-order-modal").click();
                        } else if(response.data.data.message_after_request_action_required != "" && response.data.data.message_after_request_action_required == "This bill has been paid, no online payment is needed.") {
                            this.messsageErrorRequestActionPaidOrder = response.data.data.message_after_request_action_required;
                            $(".btn-paid-order-modal").click();
                        } else {
                            if(this.selectPaymentMethod == 2) {
                                // pay with stripe
                                this.processPaymentStripe(response.data.data.transaction_id, response.data.data.total_amount_from_megapos, response.data.data.id);
                            } else {
                                // pay with minty wallet
                                var url = location.protocol + '//' + location.host+"/"+this.$store.state.merchantCode;

                                if (this.tableInfo) {
                                    url += "/QR/"+this.branchCode+"/"+this.tableCode+"/payment-success/"+response.data.data.transaction_id+"?minty_transaction_id="+response.data.data.id;
                                } else {
                                    url += "/"+this.branchCode+"/payment-success/"+response.data.data.transaction_id+"?minty_transaction_id="+response.data.data.id;
                                }

                                window.location.href = url;

                            }
                        }
                        this.payload = false;
                    }
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                    this.payload = false;
                }
                this.hideLoader();
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.setTimeOutNotification();
                this.hideLoader();
                this.payload = false;
            }
            );
        },
        startPolling(id) {
            this.getOrderByOrderId(id);

            this.intervalId = setInterval(() => {
                this.getOrderByOrderId(id);
            }, 3000);

            this.timeoutId = setTimeout(() => {
                clearInterval(this.intervalId);
                clearTimeout(this.timeoutId);
                this.hideLoader();
                $(".btn-timeout-order-modal").click();
                
            }, 180000);
        },
        closeModal() {
            if(this.messsageErrorRequestActionTotalAmount != "" || this.messsageErrorRequestActionPaidOrder != "") {
                $(".btn-close-modal").click();
                location.reload();
            }
            // setTimeout(() => {
            //     location.reload();
            // }, 10000);
            // location.reload();
        },
        gotoPath(name) {
            this.$router.push({ name: name, params: {
                merchant_code: this.merchantCode,
                type: this.pathType,
                branch_code: this.branchCode,
                table_code: this.tableCode
            } });
        },
        backToHome() {
            // this.gotoPath('Order');
            const merchantCode = this.$store.state.merchantCode;
            const branchCode = this.$store.state.branchCode;
            var url = location.protocol + '//' + location.host+"/"+merchantCode;
            if (this.tableInfo) {
                url += "/QR/" + branchCode + "/" + this.tableCode + "/order";
            } else {
                url += "/" + branchCode + "/order";
            }
            window.location.href = url;
        },
        selectInputStripe() {
            var input = document.getElementById('paymentMethodMintyWalletInput');
            input.checked = false;
            var input1 = document.getElementById('paymentMethodStripeInput');
            input1.checked = true;
            this.selectPaymentMethod = 2;
            localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
            localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
        },
        selectInputMintyWallet() {
            var amount_in_account = Number(this.memberInfo.amount_in_account);
            this.totalAmount = this.dataOrders && this.dataOrders.length > 0 ? this.dataOrders[0].total_amount_from_megapos : 0;
            if(Number(amount_in_account.toFixed(2)) >= Number(this.totalAmount)) {
                var input = document.getElementById('paymentMethodStripeInput');
                input.checked = false;
                var input1 = document.getElementById('paymentMethodMintyWalletInput');
                input1.checked = true;
                this.selectPaymentMethod = 1;
                localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                localStorage.setItem(localStorageName('PAYMENT_METHOD'), 1);
            }
        },
        hanldeSelectPaymentMethod() {
            if(this.memberInfo && this.memberInfo.amount_in_account) {
                var amount_in_account = Number(this.memberInfo.amount_in_account);
                this.totalAmount = this.dataOrders && this.dataOrders.length > 0 ? this.dataOrders[0].total_amount_from_megapos : 0;
                
                if(Number(amount_in_account.toFixed(2)) < Number(this.totalAmount)) {
                    this.disablePayWithMintyWallet = true;
                    this.selectPaymentMethod = 2;
                    localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                    localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
                } else {
                    this.disablePayWithMintyWallet = false;
                    if(this.selectPaymentMethod != 2) {
                        this.selectPaymentMethod = 1;
                        localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                        localStorage.setItem(localStorageName('PAYMENT_METHOD'), 1);
                    }
                }
            } else {
                this.disablePayWithMintyWallet = true;
                this.selectPaymentMethod = 2;
                localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
                localStorage.setItem(localStorageName('PAYMENT_METHOD'), 2);
            }
            
        },
        getMemberInfo() {
            if(this.memberInfo) {
                let dataForm = {
                    memberId: this.memberId
                };

                AuthService.getMemberInfo(dataForm).then(
                    (response) => {
                        if (response.result.isSuccess) {
                            localStorage.setItem(localStorageName('memberInfo'), JSON.stringify(response.data.user));
                            this.memberInfo = response.data.user;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                    }
                );
            }
        },
        getTopupList() {
            if(this.memberInfo) {
                MemberService.getTopupList().then(
                    (response) => {
                        this.topupList = response.data.data.topup;
                    },
                    (error) => {
                        console.log("error", error);
                        this.hideLoader();
                    }
                );
            }
        },
        checkMemberPermissions() {
            if(this.memberInfo) {
                MemberService.checkMemberPermissions().then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        this.memberPermissions = response.data.data;
                    }
                    },
                    (error) => {
                        console.log(error)
                    }
                );
            }
        },
        openModalConfirmTopup(item) {
            this.topupCurrentlyHandle = item;
            $(".btn-confirm-topup-modal").click();
        },
        processPaymentTopUp() {
            var item = this.topupCurrentlyHandle;
            const merchantCode = this.$store.state.merchantCode;
            var urlCancel = location.protocol + '//' + location.host+"/"+merchantCode;
            var url = location.protocol + '//' + location.host+"/"+merchantCode;

            if (this.tableInfo) {
                url += "/QR/" + this.branchCode + "/" + this.tableCode + "/order-history";
                urlCancel += "/QR/" + this.branchCode + "/" + this.tableCode + "/order-history";
            } else {
                url += "/" + this.branchCode + "/order-history";
                urlCancel += "/" + this.branchCode + "/order-history";
            }

            var payment_amount = new Intl.NumberFormat("en", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                            }).format(item.payment_amount)
            var dataSend = {
                success_url: url+"?session_id={CHECKOUT_SESSION_ID}&price="+item.payment_amount+"&id="+item.id,
                cancel_url: urlCancel,
                images: "",
                name: "TOP UP "+payment_amount,
                unit_amount: item.payment_amount,
                quantity: 1,
                branch_code: this.branchCode,
                isTopupOnMember: 1,
                topup_id: item.id,
                member_id: this.memberInfo.id
            };
            OrderService.checkoutSessionStripe(dataSend).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    window.location.href = response.data.data.url
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.setTimeOutNotification();
                    this.hideLoader();
                }
            );
        },
        selectReward() {
            if (this.isLogined) {
                this.$router.push({ name: 'MyReward', params: {
                    merchant_code: this.merchantCode,
                    type: this.pathType,
                    branch_code: this.branchCode,
                    table_code: this.tableCode,
                    from_order_history: true
                } });
            } else {
                $('.btn-notification-sign-in-modal').click();
            }
        },
        confirmRemoveReward() {
            $('.btn-confirm-modal').click();
        },
        removeReward() {
            $('.btn-close-modal-confirm').click();
            if (this.isOrderPayLater) {
                this.isRemoveVoucher = true;
                this.getOrdersByTable(this.typeChecked);
                this.waitingMegaposResponse = true;
                $('#order-history-page').addClass('no-clicks');
                this.intervalId = setInterval(() => {
                    this.getOrdersByTable(this.typeChecked);
                }, 1000);
            }
        }
    },
  };
  </script>
  <style scoped>
  .btn-exchange {
    color: rgb(248, 37, 37);
    font-weight: 700;
    border: 1px solid rgb(248, 37, 37);
  }
  
  .reward-tab {
    margin: 0px !important;
    padding: 0px;
    width: 100% !important;
  }
  
  .reward-tab li {
    list-style: none;
    float: left;
    width: 50%;
    text-align: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .reward-tab li.active {
    border-bottom: 2px solid var(--color);
    color: var(--color);
  }

  @media only screen and (max-width: 600px) {
    .wrap-card .card-item:last-child {
      margin-bottom: 80px !important;
    }
  }
  .container-custom {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: white;
  }

  .content-custom {
      flex: 1;
      background-color: white;
      overflow: auto;
      /* padding: 0px; */
  }
  footer {
    background-color: white;
    padding-top: 10px;
  }
  .div-order-items {
    width: 100%;display: inline-flex;
    margin-top: 8px;
}
    .no-clicks {
    pointer-events: none;
    }
  </style>