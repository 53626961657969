<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-confirm-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalConfirm"
    ></button>
    <div
      class="modal fade"
      id="modalConfirm"
      tabindex="-1"
      aria-labelledby="modalConfirmLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center text-bold">{{ message }}</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn-close-modal-confirm"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button type="button" class="btn-yes" @click="processYes">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalConfirm",
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: "",
    },
    btnYes: {
      type: String,
      default: "",
    },
    processYes: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.btn-close-modal-confirm,
.btn-yes {
  border: 1px solid orangered;
  background-color: orangered;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 30px;
  /* font-weight: bold; */
}
</style>
