<template>
  <div class="container-custom" :style="styleObject">
    <header>
      <Header></Header>
    </header>
    <div v-if="isLoadDone" class="content-custom">
      <div class="content">
        <div class="font-weight-bold d-flex align-items-center mt-5">
          <div class="me-3">Total:</div>
          <div class="color-green" style="font-size: 36px">
            {{ formatPrice(total) }}
          </div>
        </div>
        <div v-if="isPaymentProcessing">
          <div v-if="isPaynowQR">
            <div class="d-flex flex-column align-items-center" v-if="qrImage">
              <div class="font-weight-bold mt-5 text-center">
                PLEASE USE YOUR BANKING APP TO SCAN <br />
                THE QR CODE ABOVE TO MAKE PAYMENT
              </div>
              <img
                :src="qrImage"
                alt="error"
                style="width: 80%; height: auto"
              />
            </div>
            <div v-else class="text-center mt-5" style="font-size: 16px">
              Generate PAYNOW QR code ...
            </div>
          </div>
          <div v-else>
            <div v-if="paymentStatus.status">
              <div class="d-flex flex-column align-items-center">
                <div class="font-weight-bold mt-5 text-center">
                  PLEASE TAP CARD <br />
                  ON THE PAYMENT DEVICE
                </div>
                <img
                  src="@/assets/tap_to_pay.gif"
                  alt="error"
                  style="width: 80%; height: auto"
                />
              </div>
            </div>
            <div v-else>
              <div class="d-flex flex-column align-items-center">
                <img
                  src="@/assets/error.png"
                  alt="error"
                  style="width: 50%; height: auto"
                />
                <div class="font-weight-bold" style="color: red">
                  {{
                    paymentStatus.code == "terminal_reader_timeout"
                      ? "Time out"
                      : "Payment failed"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="allowCreditCard || allowPaynowQR">
            <div class="font-weight-bold mt-5">Select your payment method</div>
            <div
              v-if="allowCreditCard && readerStatus == 'online'"
              class="box-payment-method mt-5"
              @click="verifyReaderState()"
            >
              <img
                src="https://thumbs.dreamstime.com/b/kiev-ukraine-september-visa-mastercard-logos-printed-white-paper-visa-mastercard-american-multinational-102631953.jpg"
                alt="error"
              />
              <div>Credit Cards</div>
            </div>
            <div
              v-if="allowPaynowQR"
              class="box-payment-method mt-3"
              @click="paynowQR()"
            >
              <img
                src="https://www.princelandscape.com/wp-content/uploads/2019/05/paynow.png"
                alt="error"
              />
              <div>PayNOW QR</div>
            </div>
            <!-- <div class="box-payment-method mt-3">
              <img
                src="https://stg-api.mintycrm.com/storage/merchants/merchant_logo_1715070880.jpg"
                alt="error"
              />
              <div>Minty wallet</div>
            </div> -->
          </div>
          <div v-else class="mt-5 text-center font-weight-bold">
            PAYMENT METHOD NOT AVAILABLE, PLEASE CONTACT OUR STAFF FOR ASSISTANT
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div v-if="isPaymentProcessing">
        <div v-if="isPaynowQR && allowCancelPaynow" class="d-flex justify-content-center">
          <div class="ms-3 w-50 btn-order-more" @click="cancelOrder()">
            Cancel Order
          </div>
        </div>
        <div v-else>
          <div
            v-if="paymentStatus.status"
            class="d-flex justify-content-center"
          >
            <div>
              Payment will be cancelled in
              <span style="color: red">{{ seconds }}</span> seconds.
            </div>
          </div>
          <div v-else class="d-flex mt-3 w-100">
            <div class="w-50 me-3 btn-start-over" @click="retry()">Retry</div>
            <div class="ms-3 w-50 btn-order-more" @click="cancelOrder()">
              Cancel Order
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center mt-3">
        <div class="w-50 me-3 btn-start-over" @click="confirmStartOver()">
          Start Over
        </div>
        <div
          v-if="!isRetry"
          class="ms-3 w-50 btn-order-more"
          @click="gotoPath('OrderPageKiosk')"
        >
          Order More
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center mt-3">
        Powered by
        <img
          src="@/assets/logo.jpg"
          alt=""
          class="ms-2"
          style="height: 20px; width: auto"
        />
      </div>
    </div>
    <div
      class="position-fixed top-1 end-1 message-success"
      style="z-index: 99999 !important"
    >
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
    <ModalNotification :message="messageNotification" :isKiosk="true"/>
    <ModalInputBuzzerNumber :keyReload="keyReloadModalInputBuzzerNumber" :inputBuzzerNumber="inputBuzzerNumber"/>
    <ModalConfirm
      message="Confirm to cancel this order?"
      :process-yes="startOver"
    />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Header from "../kiosk/components/Header.vue";
import { localStorageName } from "@/config/utils";
import KioskService from "../../services/kiosk.service";
import ModalNotification from "@/components/ModalNotification.vue";
import ModalInputBuzzerNumber from "../kiosk/components/ModalInputBuzzerNumber.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import $ from "jquery";

export default {
  name: "PaymentSelection",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    Header,
    ModalNotification,
    ModalInputBuzzerNumber,
    ModalConfirm
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      merchantCode: "",
      branchCode: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      memberInfo: JSON.parse(
        localStorage.getItem(localStorageName("memberInfo"), "")
      ),
      isLogined: false,
      total: 0,
      itemSelect: [],
      reader: null,
      transaction: {},
      isPaymentProcessing: false,
      paymentStatus: {
        status: true,
        code: null,
      },
      isWebhookProcessing: false,
      paymentIntent: {
        id: null,
        status: null,
        metadata: {},
      },
      intervalId: null,
      readerId: "",
      isRetry: false,
      interval: null,
      messageNotification: "",
      seconds: 50,
      intervalCountdown: null,
      intervalConnectReader: null,
      stripe: null,
      isPaynowQR: false,
      qrImage: null,
      allowPaynowQR: false,
      allowCreditCard: false,
      buzzerNumber: '',
      keyReloadModalInputBuzzerNumber: "keyReloadModalInputBuzzerNumber",
      showBuzzerNumber: false,
      takeAwayOrDineIn: localStorage.getItem(localStorageName("DINING_OPTION")) ?? 2,
      timeoutId: null,
      allowCancelPaynow: true,
      readerStatus: "online",
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch("cartName");
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.itemSelect = JSON.parse(localStorage.getItem(this.cartName, []));
    this.total = this.calculateTotalBeforeDiscount().toFixed(2);

    if (!this.itemSelect || this.itemSelect.length == 0) {
      this.gotoPath("OrderPageKiosk");
    }

    if (this.memberInfo) {
      this.isLogined = true;
    }

    this.getBranchSetting();
  },
  unmounted() {
    this.clearAllInterval();
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return "$ " + parseFloat(price).toFixed(2);
    },

    confirmStartOver() {
      $(".btn-confirm-modal").click();
    },

    startOver() {
      $('.btn-close-modal-confirm').click();
      localStorage.removeItem(this.cartName);
      localStorage.removeItem(localStorageName("PAYMENT_METHOD"));
      this.gotoPath("LandingPageKiosk");
    },

    inputBuzzerNumber(number) {
      if (number.length > 0) {
        $('.btn-hide-modal-input-buzzer-number').click();
      }

      this.buzzerNumber = number;
    },

    startCountdown() {
      if (this.intervalCountdown) return;

      this.intervalCountdown = setInterval(async () => {
        if (this.seconds > 0) {
          this.seconds--;

          if (this.seconds == 2) {
            if (this.paymentIntent.id) {
              if (!this.isPaynowQR) {
                await this.cancelReaderAction();
              }
              await this.cancelPaymentIntent();
            }
          }
        } else {
          clearInterval(this.intervalCountdown);
          this.intervalCountdown = null;
          this.messageNotification = "Timeout Error";
          $(".btn-notification-modal").click();
          this.isPaynowQR = false;
          this.qrImage = null;
          this.isRetry = true;
          this.isPaymentProcessing = false;
          this.allowCancelPaynow = true;
          this.clearAllInterval();
          this.paymentStatus = {
            status: true,
            code: null,
          };
        }
      }, 1000);
    },

    gotoPath(name) {
      this.$router.push({
        name: name,
        params: {
          merchant_code: this.merchantCode,
          branch_code: this.branchCode,
        },
      });
    },

    paynowQR() {
      if (this.total == 0) {
        this.saveOrder();
      } else {
        this.isPaynowQR = true;
        this.isPaymentProcessing = true;
        this.seconds = 120;
        this.startCountdown();

        if (!this.isRetry) {
          this.saveOrder();
        } else {
          this.createPaymentIntentPaynow();
        }

        setTimeout(() => {
          this.allowCancelPaynow = false;
        }, 10000);
      }
    },

    async getBranchSetting() {
      this.showLoader();
      var dataForm = {
        branch_code: this.branchCode,
      };

      await KioskService.getBranchSetting(dataForm).then(
        async (response) => {
          if (response.data.result.isSuccess) {
            const data = response.data.data;
            this.readerId = data.terminal_id;
            this.allowCreditCard = data.allow_creditcard;
            this.allowPaynowQR = data.allow_paynow;
            this.showBuzzerNumber = data.show_buzzer_number;
            this.isLoadDone = true;

            if (this.showBuzzerNumber) {
              this.keyReload = Math.random();
              $('.btn-show-modal-input-buzzer-number').click();
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    verifyReaderState() {
      if (this.total == 0) {
        this.saveOrder();
      } else {
        this.isPaynowQR = false;
        $(".btn-close-modal").click();
        this.showLoader();
        var dataForm = {
          reader_id: this.readerId,
          api_key: this.apiKey,
        };

        KioskService.verifyReaderState(dataForm).then(
          async (response) => {
            if (response.data.status == 200) {
              this.reader = response.data.data;
              this.readerStatus = this.reader.readerStatus;

              if (this.reader.readerStatus == "offline") {
                this.messageNotification =
                  "Payment terminal is not online, please contact our staff for assistant";
                $(".btn-notification-modal").click();

                // this.intervalConnectReader = setInterval(() => {
                //   this.verifyReaderState();
                // }, 30000);
              } else {
                // clearInterval(this.intervalConnectReader);
                // this.intervalConnectReader = null;

                if (!this.isRetry) {
                  this.saveOrder();
                } else {
                  await this.createPaymentIntent();
                }

                this.isRetry = false;
              }
            } else {
              this.snackbar = "danger";
              this.message = "Reader is not connected!";
              this.setTimeOutNotification();
            }

            this.hideLoader();
          },
          (error) => {
            console.log(error);
            this.snackbar = "danger";
            this.message = "Reader is not connected!";
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    async createPaymentIntent() {
      this.showLoader();
      var dataForm = {
        amount: this.total,
        transaction_id: this.transaction.transaction_id,
        receipt_no: this.transaction.receipt_no,
        reader_id: this.readerId,
        branch_code: this.branchCode,
      };

      await KioskService.createPaymentIntent(dataForm).then(
        (response) => {
          this.isPaymentProcessing = true;

          if (response.data.status == 200) {
            this.seconds = 50;
            const paymentIntent = response.data.data;
            this.paymentIntent = {
              id: paymentIntent.id,
              status: true,
              metadata: {},
            };

            setTimeout(() => {
              this.requestPaymentReader();
            }, 100);
          } else {
            this.paymentStatus.status = false;
            this.paymentStatus.code = response.data.data?.stripeError?.code;
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async createPaymentIntentPaynow() {
      this.showLoader();
      var dataForm = {
        branch_code: this.branchCode,
        amount: this.total,
        transaction_id: this.transaction.transaction_id,
        receipt_no: this.transaction.receipt_no,
      };

      await KioskService.createPaymentIntentPaynow(dataForm).then(
        (response) => {
          if (response.data.status == 200) {
            const paymentIntent = response.data.data;

            this.paymentIntent = {
              id: paymentIntent.id,
              status: true,
              metadata: {},
            };

            setTimeout(() => {
              this.processPaymentPaynow();
            }, 100);
          } else {
            this.paymentStatus.status = false;
            this.paymentStatus.code = response.data.data?.stripeError?.code;
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async processPaymentPaynow() {
      this.showLoader();
      var dataForm = {
        payment_intent_id: this.paymentIntent.id,
      };

      await KioskService.processPaymentPaynow(dataForm).then(
        (response) => {
          if (response.data.status == 200) {
            const data = response.data.data;

            if (data.next_action) {
              this.qrImage =
                data.next_action.paynow_display_qr_code.image_url_png;

              this.interval = setInterval(async () => {
                this.checkPaymentIntentStatus();
              }, 3000);
            }
          } else {
            this.paymentStatus.status = false;
            this.paymentStatus.code = response.data.data?.stripeError?.code;
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async requestPaymentReader() {
      this.showLoader();
      var dataForm = {
        reader_id: this.readerId,
        payment_intent_id: this.paymentIntent.id,
      };

      await KioskService.requestPaymentReader(dataForm).then(
        (response) => {
          if (response.data.status == 200) {
            clearInterval(this.intervalCountdown);
            this.intervalCountdown = null;
            this.startCountdown();

            this.interval = setInterval(async () => {
              this.checkPaymentIntentStatus();
            }, 3000);
          } else {
            this.paymentStatus.status = false;
            this.paymentStatus.code = response.data.data?.stripeError?.code;

            if (this.paymentIntent.id) {
              this.cancelPaymentIntent();
              this.cancelReaderAction();
            }
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async cancelPaymentIntent() {
      this.showLoader();
      var dataForm = {
        payment_intent_id: this.paymentIntent.id,
      };

      await KioskService.cancelPaymentIntent(dataForm).then(
        async (response) => {
          if (response.data.status == 200) {
            console.log("success");
          } else {
            this.snackbar = "danger";
            this.message = response.message;
            this.setTimeOutNotification();
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async cancelReaderAction() {
      this.showLoader();
      var dataForm = {
        reader_id: this.readerId,
        payment_intent_id: this.paymentIntent.id,
      };

      await KioskService.cancelReaderAction(dataForm).then(
        async (response) => {
          if (response.data.result.isSuccess) {
            console.log("success");
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }

          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async cancelOrder() {
      // this.isRetry = true;
      // this.isPaymentProcessing = false;
      this.clearAllInterval();
      // this.paymentStatus = {
      //   status: true,
      //   code: null,
      // };

      if (this.paymentIntent.id) {
        if (!this.isPaynowQR) {
          await this.cancelReaderAction();
        }
        await this.cancelPaymentIntent();
      }

      this.startOver();

      // this.isPaynowQR = false;
      // this.qrImage = null;
    },

    async checkPaymentIntentStatus() {
      var dataForm = {
        reader_id: this.readerId,
        payment_intent_id: this.paymentIntent.id,
        is_paynowQR: this.isPaynowQR,
      };

      await KioskService.checkPaymentIntentStatus(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reader = response.data.data.reader;

            if (
              this.reader &&
              this.reader.status == "offline" &&
              !this.isPaynowQR
            ) {
              this.readerStatus = 'offline';
              this.retry();
              this.messageNotification =
                "Payment terminal is not online, please contact our staff for assistant";
              $(".btn-notification-modal").click();

              // this.intervalConnectReader = setInterval(() => {
              //   this.verifyReaderState();
              // }, 30000);
            } else {
              const status = response.data.data.status;
              this.paymentIntent.status = status;

              if (status == "succeeded") {
                localStorage.removeItem(this.cartName);
                clearInterval(this.interval);
                this.interval = null;
                clearInterval(this.intervalCountdown);
                this.intervalCountdown = null;

                this.$router.push({
                  name: "PaymentSuccessPageKiosk",
                  params: {
                    merchant_code: this.merchantCode,
                    branch_code: this.branchCode,
                    transaction_id: this.transaction.transaction_id,
                  },
                });
              }
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        async (error) => {
          if (error.response.data.result.statusCode == 400) {
            this.messageNotification = error.response.data.result.message;
            $(".btn-notification-modal").click();
            clearInterval(this.interval);
            this.interval = null;
            clearInterval(this.intervalCountdown);
            this.intervalCountdown = null;
            this.paymentStatus.status = false;
            this.paymentStatus.code = error.response.data.result.statusCode;

            if (this.paymentIntent.id) {
              this.cancelPaymentIntent();
              this.cancelReaderAction();
            }
          } else {
            this.snackbar = "danger";
            this.message = error.response.data.result.message;
            this.setTimeOutNotification();
          }
        }
      );
    },

    saveOrder() {
      this.showLoader();
      let dataForm = {
        branch_code: this.branchCode,
        items: this.itemSelect,
        take_away_fee: this.totalDineInFee().toFixed(2),
        total_item: this.calculateTotal(),
        total_payment_amount: this.total,
        gst_amount: this.convertedValueGstRate().toFixed(2),
        gst_rate: this.branch.gst_rate,
        buzzer_number: this.buzzerNumber,
        take_away_or_dine_in: this.takeAwayOrDineIn
      };

      KioskService.saveOrder(dataForm).then(
        async (response) => {
          if (response.data.result.isSuccess) {
            this.transaction = response.data.data;

            if (this.total == 0) {
              this.$router.push({
                name: "PaymentSuccessPageKiosk",
                params: {
                  merchant_code: this.merchantCode,
                  branch_code: this.branchCode,
                  transaction_id: this.transaction.transaction_id,
                },
              });
            }

            if (this.isPaynowQR) {
              this.createPaymentIntentPaynow();
            } else {
              this.createPaymentIntent();
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async retry() {
      this.isRetry = true;
      this.isPaymentProcessing = false;
      this.clearAllInterval();
      this.paymentStatus = {
        status: true,
        code: null,
      };

      if (this.paymentIntent.id) {
        await this.cancelReaderAction();
        await this.cancelPaymentIntent();
      }
    },

    convertedValueGstRate() {
      const numericValue = parseFloat(this.branch.gst_rate);

      if (numericValue > 0) {
        // exclusive
        return (
          (this.calculateTotal() + this.totalDineInFee()) * (numericValue / 100)
        );
      } else {
        // inclusive
        return 0;
      }
    },

    totalDineInFee() {
      if(this.takeAwayOrDineIn == 2 && this.branch.service_charge_rate > 0) {
        return this.calculateTotal() * (this.branch.service_charge_rate / 100);
      } else {
        return 0;
      }
    },

    calculateTotal() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        return this.itemSelect.reduce((total, product) => {
          if (this.isLogined) {
            total += product.member_price
              ? product.member_price * product.quantity
              : 0;
          } else {
            total += product.price_default
              ? product.price_default * product.quantity
              : 0;
          }

          return total;
        }, 0);
      } else {
        return 0;
      }
    },

    calculateTotalBeforeDiscount() {
      if (this.itemSelect && this.itemSelect.length > 0) {
        if (parseFloat(this.branch.gst_rate) < 0) {
          // INCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total += this.totalDineInFee();
          return total > 0 ? total : 0;
        } else {
          // EXCLUSIVE
          let total = this.itemSelect.reduce((total, product) => {
            if (this.isLogined) {
              total += product.member_price
                ? product.member_price * product.quantity
                : 0;
            } else {
              total += product.price_default
                ? product.price_default * product.quantity
                : 0;
            }

            return total;
          }, 0);

          total += this.totalDineInFee() + this.convertedValueGstRate();
          return total > 0 ? total : 0;
        }
      } else {
        return 0;
      }
    },

    clearAllInterval() {
      clearInterval(this.interval);
      this.interval = null;
      clearInterval(this.intervalCountdown);
      this.intervalCountdown = null;
      // clearInterval(this.intervalConnectReader);
      // this.intervalConnectReader = null;
      // clearTimeout(this.timeoutId);
      // this.timeoutId = null
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>

<style scoped>
.content {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}

.box-payment-method {
  width: 420px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  padding: 15px;
  min-height: 150px;
}

.box-payment-method img {
  width: 125px;
  height: auto;
}

.box-payment-method div {
  font-weight: bold;
  padding-left: 15px;
}

.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-custom {
  flex: 1;
  background-color: white;
  overflow: auto;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 15px;
  padding-bottom: 5px;
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translate(-50%);
}

.btn-start-over,
.btn-order-more {
  border: 1px solid #d3d3d3;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
}
</style>
