<template>
  <div>
    <button
      style="display: none;"
      type="button"
      class="btn-notification-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalNotification"
    ></button>
    <div
      class="modal fade"
      id="modalNotification"
      tabindex="-1"
      aria-labelledby="modalNotificationLabel"
      aria-hidden="true"
      style="z-index: 999999 !important"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center text-bold">{{ message }}</div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              type="button"
              class="btn-close-modal"
              :class="{ 'kiosk' : isKiosk }"
              data-bs-dismiss="modal"
              @click="closeModalNotificationHandler"
            >
              Ok
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalNotification",
  props: {
    message: {
      type: String,
      default: "",
    },
    isKiosk: {
      type: Boolean,
      default: false,
    },
    closeModalNotificationHandler: {
      type: Function,
      default: () => { },
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: hidden !important;
}

.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
}

.btn-close-modal:not(.kiosk) {
  border: 1px solid orangered;
  background-color: orangered;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 30px;
  /* font-weight: bold; */
}

.btn-close-modal.kiosk {
  border: 1px solid #d3d3d3;
  padding: 5px 30px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  background: none;
}
</style>
