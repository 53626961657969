<template>
  <div :style="styleObject">
    <button
      style="display: none"
      type="button"
      class="btn-show-item-detail-modal"
      data-bs-toggle="modal"
      data-bs-target="#modal_item_detail"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-hide-item-detail-modal"
      data-bs-target="#modal_item_detail"
      data-bs-dismiss="modal"
    ></button>
    <div id="custom-overlay" class="custom-modal-overlay"></div>
    <div
      class="modal modal-full fade"
      id="modal_item_detail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modal_item_detail"
      style="z-index: 10000"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div
            class="modal-body"
            style="padding: 0px"
            v-on:scroll.passive="handleScroll($event)"
          >
            <div class="btn-close-modal" @click="closeModel()">
              <div>&times;</div>
            </div>
            <div class="page-header align-items-start" v-if="isLoadDone">
              <div class="container">
                <div class="content">
                  <div class="item-img-container">
                    <div class="image-container">
                      <img
                        :src="itemInfo.image"
                        alt="Food Image"
                        class="food-image"
                      />
                    </div>
                    <div
                      class="mt-3 pad-left-right"
                      style="display: flex; align-items: flex-start"
                    >
                      <div
                        class="item-name"
                        style="width: 70%; line-height: normal"
                      >
                        {{ itemInfo.item_name }}
                      </div>
                      <div style="width: 2%"></div>
                      <div
                        class="item-name"
                        style="
                          width: 28%;
                          text-align: right;
                          display: grid;
                          justify-content: end;
                        "
                      >
                        <span
                          style="
                            line-height: normal;
                            font-size: 16px;
                            margin-top: 3.5px;
                          "
                          >S${{
                            isLogined
                              ? itemInfo.member_price
                                ? (
                                    itemInfo.member_price * itemInfo.quantity
                                  ).toFixed(2)
                                : "0.00"
                              : itemInfo.price_default
                              ? (
                                  itemInfo.price_default * itemInfo.quantity
                                ).toFixed(2)
                              : "0.00"
                          }}</span
                        >
                      </div>
                    </div>
                    <div
                      class="item-description fs-11 w-100 mt-1 pad-left-right"
                    >
                      {{ itemInfo.description }}
                    </div>
                  </div>
                  <div
                    class="banner mt-3 pad-left-right"
                    v-if="itemInfo.slides.length"
                  >
                    <swiper
                      :slides-per-view="1"
                      :space-between="0"
                      :loop="true"
                      :autoplay="{
                        delay: 3000,
                        disableOnInteraction: false,
                      }"
                      :pagination="{
                        clickable: true,
                        el: '.swiper-pagination',
                      }"
                    >
                      <swiper-slide
                        v-for="(value, index) in itemInfo.slides"
                        :key="index"
                      >
                        <div
                          class="slider"
                          @click="clickLink(value)"
                          style="
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <div
                            v-if="
                              value.direct_to_suggested_adds_on == 1 &&
                              value.itemSelected
                            "
                            style="
                              position: absolute;
                              width: 90%;
                              height: 90%;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <div
                              style="
                                width: 100%;
                                height: 80%;
                                display: inline-flex;
                                gap: 2%;
                              "
                            >
                              <div style="width: 49%; height: 100%">
                                <img
                                  :src="value.itemSelected.image"
                                  alt="error"
                                  style="height: 100%"
                                />
                              </div>
                              <div style="width: 49%">
                                <div
                                  style="
                                    background: gray;
                                    color: white;
                                    padding-left: 10px;
                                  "
                                >
                                  <div>
                                    ADDS ON S${{ value.itemSelected.price }}
                                  </div>
                                </div>
                                <div
                                  style="
                                    background: gray;
                                    color: white;
                                    margin-top: 5px;
                                    padding-left: 10px;
                                  "
                                >
                                  <div>{{ value.itemSelected.item_name }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <img :src="value.file_url" alt="error" />
                        </div>
                      </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination"></div>
                  </div>
                  <div
                    v-if="itemInfo.bundle_item.length"
                    style="margin-top: 20px; margin-bottom: 20px"
                  >
                    <div
                      v-for="(bundleItem, index) in itemInfo.bundle_item"
                      :key="index"
                    >
                      <div
                        style="display: table; width: 100%; margin-top: 8px"
                        class="pad-left-right"
                      >
                        <div style="display: table-cell; width: auto">
                          <strong>{{ bundleItem.itemName }} </strong>
                          <br />{{ bundleItem.description }}
                        </div>
                        <div
                          style="
                            width: 70px;
                            display: table-cell;
                            vertical-align: top;
                            padding: 5px;
                          "
                        >
                          &nbsp;x&nbsp;<strong>{{
                            bundleItem.quantity
                          }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="choice mt-3 pad-left-right"
                    v-if="itemInfo.choice"
                  >
                    <div
                      class="choice-list d-flex text-center mt-1 fs-13 font-weight-bold"
                    >
                      <div
                        v-for="(choice, index) in itemInfo.choices"
                        :key="index"
                        class="w-50 mx-3"
                        :class="['choice-' + index, { active: index == 0 }]"
                        @click="selectChoice(index)"
                      >
                        {{ choice.name }}
                      </div>
                    </div>
                    <div class="item-list">
                      <div
                        class="item-detail"
                        v-for="(item, indexChoice) in choiceSelected.items"
                        :key="indexChoice"
                      >
                        <div v-if="item.item_detail" class="item-img-container">
                          <div
                            class="mt-3 pad-left-right"
                            style="display: flex; align-items: flex-start"
                          >
                            <div
                              class="item-name"
                              style="width: 70%; line-height: normal"
                            >
                              {{
                                item.qty + " x " + item.item_detail.item_name
                              }}
                            </div>
                          </div>
                          <div
                            class="item-description fs-11 w-100 mt-1 pad-left-right"
                          >
                            {{ item.item_detail.description }}
                          </div>
                          <item-modifier
                            :key="reloadModifier"
                            :itemInfo="item.item_detail"
                            :selectOnlyItem="selectOnlyItemChoice"
                            :selectMultiItem="selectMultiItemChoice"
                            :removeModifierOptionGroup="
                              removeModifierOptionGroupChoice
                            "
                            :addModifierOptionGroup="
                              addModifierOptionGroupChoice
                            "
                            :indexChoice="indexChoice"
                          >
                          </item-modifier>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <item-modifier
                      :itemInfo="itemInfo"
                      :selectOnlyItem="selectOnlyItem"
                      :selectMultiItem="selectMultiItem"
                      :removeModifierOptionGroup="removeModifierOptionGroup"
                      :addModifierOptionGroup="addModifierOptionGroup"
                    >
                    </item-modifier>
                  </div>
                </div>
                <div class="add-to-cart">
                  <div class="total-item" style="width: 37% !important">
                    <div class="d-flex align-items-center" style="width: 100%">
                      <i
                        style="width: 30%; text-align: left; font-size: 25px"
                        class="material-icons-round opacity-10 cursor-pointer"
                        @click="itemInfo.quantity > 1 ? removeItem() : null"
                        >remove</i
                      >
                      <div class="item-quantity" style="width: 40%">
                        {{ itemInfo.quantity }}
                      </div>
                      <i
                        style="width: 30%; text-align: right; font-size: 25px"
                        class="material-icons-round opacity-10 cursor-pointer"
                        @click="addItem()"
                        >add</i
                      >
                    </div>
                  </div>
                  <div style="width: 3%"></div>
                  <material-button
                    style="width: 60% !important"
                    class="btn-add-to-cart"
                    :class="{ disable: itemInfo.disable_add_to_cart }"
                    @click="addToCart()"
                  >
                    <div style="font-family: 'Inter', sans-serif">
                      Add to cart
                    </div>
                    <!-- <div style="font-family: 'Inter', sans-serif;">
                        S$
                        {{
                          isLogined ? (itemInfo.member_price ? (itemInfo.member_price * itemInfo.quantity).toFixed(2) : "0.00") : (itemInfo.price_default ? (itemInfo.price_default * itemInfo.quantity).toFixed(2) : "0.00")
                        }}
                      </div> -->
                  </material-button>
                </div>
              </div>
            </div>
            <material-loading :active="loaderActive" />
          </div>
        </div>
        <div class="item-name-top">
          <div>{{ itemInfo.item_name }}</div>
        </div>
      </div>
    </div>
  </div>

  <ModalNotification :message="messError"></ModalNotification>
</template>

<script>
import OrderService from "../../../services/order.service";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModalNotification from "@/components/ModalNotification.vue";
import $ from "jquery";
import EventBus from "vue3-eventbus";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { localStorageName } from "@/config/utils";
import "bootstrap/dist/js/bootstrap.bundle";
import ItemModifier from "../../components/ItemModifier.vue";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Item Detail",
  components: {
    MaterialButton,
    MaterialLoading,
    Swiper,
    SwiperSlide,
    ModalNotification,
    ItemModifier,
  },
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      required: true,
    },
    itemDetail: {
      type: Object,
      required: true,
    },
    isLogined: {
      type: Boolean,
      default: false,
    },
    reloadModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadDone: false,
      loaderActive: false,
      merchantCode: "",
      branchCode: "",
      branch: JSON.parse(
        localStorage.getItem(localStorageName("BRANCH_INFO"), "")
      ),
      itemInfo: {},
      disableBtnAddToCart: false,
      getModifierToShowSlideSelected: [],
      cartName: "ORDER_INFO",
      reloadModal1: "reload-item-detail",
      originalChoices: [],
      choiceSelected: {},
      reloadModifier: "reloadModifier",
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch("cartName");
  },
  beforeUnmount() {
    window.removeEventListener("popstate", this.closeModel(true));
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    formatPrice(price) {
      return "S$ " + parseFloat(price).toFixed(2);
    },

    closeModel(isPopState = false) {
      if (!isPopState) {
        this.$emit("clear-data-item");
      }

      $(".btn-hide-item-detail-modal").click();
    },

    handleScroll(event) {
      var scrollTop = event.srcElement.scrollTop;
      var scrollMax = 16 + $(".item-image").height() + 16;

      if (scrollTop >= scrollMax) {
        $(".item-name-top").addClass("active");
      } else {
        if (scrollTop < scrollMax) {
          $(".item-name-top").removeClass("active");
        }
      }
    },

    showHideModifier(index) {
      if (this.itemInfo.modifier_group[index]) {
        if (this.itemInfo.modifier_group[index].is_show) {
          this.itemInfo.modifier_group[index].is_show = false;
        } else {
          this.itemInfo.modifier_group.forEach((item, idx) => {
            this.itemInfo.modifier_group[idx].is_show = false;
          });

          this.itemInfo.modifier_group[index].is_show = true;
        }
      }
    },

    selectChoice(index) {
      this.reloadModifier = Math.random();
      const originalChoices = JSON.parse(this.originalChoices);
      this.choiceSelected = originalChoices[index];

      $(".choice-list > div").removeClass("active");
      $(".choice-list > .choice-" + index).addClass("active");

      this.itemInfo.price_default_no_modifier_option = this.choiceSelected.default_price;
      this.itemInfo.price_default = this.choiceSelected.default_price;
      this.itemInfo.member_price_no_modifier_option = this.choiceSelected.member_price;
      this.itemInfo.member_price = this.choiceSelected.member_price;
    },

    addModifierOptionGroup(
      indexChoice = -1,
      index,
      index2,
      index3 = -1,
      index4 = -1
    ) {
      console.log(indexChoice);
      let modifierGroup = this.itemInfo.modifier_group[index];
      let modifierGroupItem = this.itemInfo.modifier_group[index].items[index2];
      // let parentModifierGroupItem = this.itemInfo.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 != -1) {
        parentTotalSelectd =
          this.itemInfo.modifier_group[index].total_selected ?? 1;
        modifierGroup = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3];
        modifierGroupItem = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (
          modifierGroupItem.quantity < modifierGroupItem.item_max_qty &&
          modifierGroup.total_selected < modifierGroup.max_qty
        ) {
          modifierGroupItem.quantity += 1;
          this.itemInfo.member_price +=
            modifierGroupItem.price_default * parentTotalSelectd;
          this.itemInfo.price_default +=
            modifierGroupItem.price_default * parentTotalSelectd;
          modifierGroup.total_selected += 1;

          // if (index3 != - 1) {
          //   parentModifierGroupItem.price_default += modifierGroupItem.price_default;
          // }

          this.checkAvailableAddToCart();
        }
      }
    },

    addModifierOptionGroupChoice(
      indexChoice = -1,
      index,
      index2,
      index3 = -1,
      index4 = -1
    ) {
      let itemSelect = this.choiceSelected.items[indexChoice].item_detail;
      const itemChoiceQty = this.choiceSelected.items[indexChoice].qty;

      let modifierGroup = itemSelect.modifier_group[index];
      let modifierGroupItem = itemSelect.modifier_group[index].items[index2];
      // let parentModifierGroupItem = itemSelect.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 != -1) {
        parentTotalSelectd =
          itemSelect.modifier_group[index].total_selected ?? 1;
        modifierGroup =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3];
        modifierGroupItem =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (
          modifierGroupItem.quantity < modifierGroupItem.item_max_qty &&
          modifierGroup.total_selected < modifierGroup.max_qty
        ) {
          modifierGroupItem.quantity += 1;
          this.itemInfo.member_price +=
            modifierGroupItem.price_default *
            parentTotalSelectd *
            itemChoiceQty;
          this.itemInfo.price_default +=
            modifierGroupItem.price_default *
            parentTotalSelectd *
            itemChoiceQty;
          modifierGroup.total_selected += 1;

          if (index3 == -1) {
            if (
              modifierGroupItem.item_in_mog &&
              modifierGroupItem.item_in_mog.modifier_group
            ) {
              modifierGroupItem.item_in_mog.modifier_group.forEach(
                (group) => {
                  group.items.forEach((itemModifier) => {
                    if (itemModifier.selected) {
                      this.itemInfo.price_default +=
                        itemModifier.price_default *
                        itemModifier.quantity *
                        itemChoiceQty;
                      this.itemInfo.member_price +=
                        itemModifier.price_default *
                        itemModifier.quantity *
                        itemChoiceQty;
                    }
                  });
                }
              );
            }
          }

          // if (index3 != - 1) {
          //   parentModifierGroupItem.price_default += modifierGroupItem.price_default;
          // }

          this.checkAvailableAddToCart();
        }
      }
    },

    removeModifierOptionGroup(
      indexChoice = -1,
      index,
      index2,
      index3 = -1,
      index4 = -1
    ) {
      console.log(indexChoice);
      let modifierGroup = this.itemInfo.modifier_group[index];
      let modifierGroupItem = this.itemInfo.modifier_group[index].items[index2];
      // let parentModifierGroupItem = this.itemInfo.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 != -1) {
        parentTotalSelectd =
          this.itemInfo.modifier_group[index].total_selected ?? 1;
        modifierGroup = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3];
        modifierGroupItem = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (modifierGroupItem.quantity > 1) {
          modifierGroupItem.quantity -= 1;
          this.itemInfo.member_price -=
            modifierGroupItem.price_default * parentTotalSelectd;
          this.itemInfo.price_default -=
            modifierGroupItem.price_default * parentTotalSelectd;
          modifierGroup.total_selected -= 1;

          // if (index3 != - 1) {
          //   parentModifierGroupItem.price_default -= modifierGroupItem.price_default;
          // }

          this.checkAvailableAddToCart();
        }
      }
    },

    removeModifierOptionGroupChoice(
      indexChoice = -1,
      index,
      index2,
      index3 = -1,
      index4 = -1
    ) {
      let itemSelect = this.choiceSelected.items[indexChoice].item_detail;
      const itemChoiceQty = this.choiceSelected.items[indexChoice].qty;

      let modifierGroup = itemSelect.modifier_group[index];
      let modifierGroupItem = itemSelect.modifier_group[index].items[index2];
      // let parentModifierGroupItem = itemSelect.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 != -1) {
        parentTotalSelectd =
          itemSelect.modifier_group[index].total_selected ?? 1;
        modifierGroup =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3];
        modifierGroupItem =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (modifierGroupItem.quantity > 1) {
          modifierGroupItem.quantity -= 1;
          this.itemInfo.member_price -=
            modifierGroupItem.price_default *
            parentTotalSelectd *
            itemChoiceQty;
          this.itemInfo.price_default -=
            modifierGroupItem.price_default *
            parentTotalSelectd *
            itemChoiceQty;
          modifierGroup.total_selected -= 1;

          if (index3 == -1) {
            if (
              modifierGroupItem.item_in_mog &&
              modifierGroupItem.item_in_mog.modifier_group
            ) {
              modifierGroupItem.item_in_mog.modifier_group.forEach(
                (group) => {
                  group.items.forEach((itemModifier) => {
                    if (itemModifier.selected) {
                      this.itemInfo.price_default -=
                        itemModifier.price_default *
                        itemModifier.quantity *
                        itemChoiceQty;
                      this.itemInfo.member_price -=
                        itemModifier.price_default *
                        itemModifier.quantity *
                        itemChoiceQty;
                    }
                  });
                }
              );
            }
          }

          // if (index3 != - 1) {
          //   parentModifierGroupItem.price_default -= modifierGroupItem.price_default;
          // }

          this.checkAvailableAddToCart();
        }
      }
    },

    selectOnlyItem(
      indexChoice = -1,
      index,
      index2,
      item,
      index3 = -1,
      index4 = -1
    ) {
      console.log(indexChoice);
      let modifierGroup = this.itemInfo.modifier_group[index];
      let modifierGroupItem = this.itemInfo.modifier_group[index].items[index2];

      if (index3 == -1) {
        modifierGroup.items.forEach((item) => {
          item.is_show_modifier = false;
        });

        modifierGroupItem.is_show_modifier = !modifierGroupItem.is_show_modifier;
      } else {
        modifierGroup = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3];
        modifierGroupItem = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (!modifierGroupItem.is_not_available) {
          if (!modifierGroupItem.selected) {
            this.itemInfo.member_price +=
              modifierGroupItem.price_default * modifierGroupItem.quantity;
            this.itemInfo.price_default +=
              modifierGroupItem.price_default * modifierGroupItem.quantity;
          }

          modifierGroup.items.forEach(async (itemModifier, idx) => {
            if (idx != (index3 == -1 ? index2 : index4)) {
              if (itemModifier.selected) {
                this.itemInfo.member_price -=
                  itemModifier.price_default * itemModifier.quantity;
                this.itemInfo.price_default -=
                  itemModifier.price_default * itemModifier.quantity;

                if (
                  itemModifier.item_in_mog &&
                  itemModifier.item_in_mog.modifier_group
                ) {
                  itemModifier.item_in_mog.modifier_group.forEach(
                    (subGroup) => {
                      subGroup.items.forEach((subItemModifier) => {
                        if (subItemModifier.selected) {
                          subItemModifier.selected = false;
                          this.itemInfo.member_price -=
                            subItemModifier.price_default *
                            subItemModifier.quantity *
                            itemModifier.quantity;
                          this.itemInfo.price_default -=
                            subItemModifier.price_default *
                            subItemModifier.quantity *
                            itemModifier.quantity;
                        }
                      });
                    }
                  );
                }

                modifierGroup.items[idx].quantity = 1;
              }
              itemModifier.selected = false;
            } else {
              if (!itemModifier.selected) {
                itemModifier.selected = true;

                if (index3 == -1) {
                  modifierGroupItem.item_in_mog = await this.gotoOrderDetail(
                    this.categoryId,
                    item.id,
                    item
                  );
                }
              }
            }
          });
        }
      }
    },

    selectOnlyItemChoice(
      indexChoice = -1,
      index,
      index2,
      item,
      index3 = -1,
      index4 = -1
    ) {
      let itemSelect = this.choiceSelected.items[indexChoice].item_detail;
      const itemChoiceQty = this.choiceSelected.items[indexChoice].qty;
      let modifierGroup = itemSelect.modifier_group[index];
      let modifierGroupItem = itemSelect.modifier_group[index].items[index2];

      if (index3 == -1) {
        modifierGroup.items.forEach((item) => {
          item.is_show_modifier = false;
        });

        modifierGroupItem.is_show_modifier = !modifierGroupItem.is_show_modifier;
      } else {
        modifierGroup =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3];
        modifierGroupItem =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (!modifierGroupItem.is_not_available) {
          if (!modifierGroupItem.selected) {
            this.itemInfo.member_price +=
              modifierGroupItem.price_default *
              modifierGroupItem.quantity *
              itemChoiceQty;
            this.itemInfo.price_default +=
              modifierGroupItem.price_default *
              modifierGroupItem.quantity *
              itemChoiceQty;
          }

          modifierGroup.items.forEach(async (itemModifier, idx) => {
            if (idx != (index3 == -1 ? index2 : index4)) {
              if (itemModifier.selected) {
                this.itemInfo.member_price -=
                  itemModifier.price_default *
                  itemModifier.quantity *
                  itemChoiceQty;
                this.itemInfo.price_default -=
                  itemModifier.price_default *
                  itemModifier.quantity *
                  itemChoiceQty;

                if (
                  itemModifier.item_in_mog &&
                  itemModifier.item_in_mog.modifier_group
                ) {
                  itemModifier.item_in_mog.modifier_group.forEach(
                    (subGroup) => {
                      subGroup.items.forEach((subItemModifier) => {
                        if (subItemModifier.selected) {
                          subItemModifier.selected = false;
                          this.itemInfo.member_price -=
                            subItemModifier.price_default *
                            subItemModifier.quantity *
                            itemModifier.quantity *
                            itemChoiceQty;
                          this.itemInfo.price_default -=
                            subItemModifier.price_default *
                            subItemModifier.quantity *
                            itemModifier.quantity *
                            itemChoiceQty;
                        }
                      });
                    }
                  );
                }

                modifierGroup.items[idx].quantity = 1;
              }
              itemModifier.selected = false;
            } else {
              if (!itemModifier.selected) {
                itemModifier.selected = true;

                if (index3 == -1) {
                  modifierGroupItem.item_in_mog = await this.gotoOrderDetail(
                    this.categoryId,
                    item.id,
                    item,
                    itemChoiceQty
                  );
                }
              }
            }
          });
        }
      }
    },

    async gotoOrderDetail(categoryId, id, item, itemChoiceQty = 1) {
      // console.log(stock_balance, is_not_available, stock_enable_balance, item)
      // if(this.checkAvailableItem(item) && item.isHaveModifierOptionGroups > 0) {
      let data = {};

      if (this.checkAvailableItem(item)) {
        this.categoryIdSelect = categoryId;
        this.itemIdSelect = id;
        // this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: categoryId,
          item_id: id,
          branch_code: this.branch.branch_code ?? "",
        };
        await OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if (
                response.data.data.modifier_group &&
                response.data.data.modifier_group.length > 0
              ) {
                data = response.data.data;
                this.itemInfo.price_default +=
                  (data.price_default - data.price_default_no_modifier_option) *
                  itemChoiceQty;
                this.itemInfo.member_price +=
                  (data.price_default - data.price_default_no_modifier_option) *
                  itemChoiceQty;
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            // this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            // this.hideLoader();
          }
        );
      }

      return data;
    },

    async selectMultiItem(
      indexChoice = -1,
      index,
      index2,
      item,
      index3 = -1,
      index4 = -1
    ) {
      console.log(indexChoice);
      let modifierGroup = this.itemInfo.modifier_group[index];
      let modifierGroupItem = this.itemInfo.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 == -1) {
        modifierGroupItem.is_show_modifier = !modifierGroupItem.is_show_modifier;
      } else {
        parentTotalSelectd =
          this.itemInfo.modifier_group[index].total_selected ?? 1;
        modifierGroup = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3];
        modifierGroupItem = this.itemInfo.modifier_group[index].items[index2]
          .item_in_mog.modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (!modifierGroupItem.is_not_available) {
          if (
            !modifierGroupItem.selected &&
            modifierGroup.total_selected >= modifierGroup.max_qty
          ) {
            return;
          }

          modifierGroupItem.selected = !modifierGroupItem.selected;
          const modifierGroupItemQty = modifierGroupItem.quantity;

          if (modifierGroupItem.selected) {
            modifierGroup.total_selected += 1;
            this.itemInfo.member_price +=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd;
            this.itemInfo.price_default +=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd;

            modifierGroup.items_selected.push(index2);

            if (index3 == -1) {
              modifierGroupItem.item_in_mog = await this.gotoOrderDetail(
                this.categoryId,
                item.id,
                item
              );
            }
          } else {
            modifierGroup.total_selected -= modifierGroupItemQty;
            this.itemInfo.member_price -=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd;
            this.itemInfo.price_default -=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd;

            if (index3 == -1) {
              if (
                modifierGroupItem.item_in_mog &&
                modifierGroupItem.item_in_mog.modifier_group
              ) {
                modifierGroupItem.item_in_mog.modifier_group.forEach(
                  (group) => {
                    group.items.forEach((itemModifier) => {
                      if (itemModifier.selected) {
                        itemModifier.selected = false;
                        this.itemInfo.price_default -=
                          itemModifier.price_default *
                          itemModifier.quantity *
                          modifierGroupItemQty;
                        this.itemInfo.member_price -=
                          itemModifier.price_default *
                          itemModifier.quantity *
                          modifierGroupItemQty;
                      }
                    });
                  }
                );
              }

              modifierGroupItem.item_in_mog = {};
            }

            modifierGroupItem.quantity = 1;
            modifierGroup.items_selected.splice(
              modifierGroup.items_selected.indexOf(index2)
            );
          }

          this.checkAvailableAddToCart();
        }
      }
    },

    async selectMultiItemChoice(
      indexChoice = -1,
      index,
      index2,
      item,
      index3 = -1,
      index4 = -1
    ) {
      let itemSelect = this.choiceSelected.items[indexChoice].item_detail;
      const itemChoiceQty = this.choiceSelected.items[indexChoice].qty;

      let modifierGroup = itemSelect.modifier_group[index];
      let modifierGroupItem = itemSelect.modifier_group[index].items[index2];
      let parentTotalSelectd = 1;

      if (index3 == -1) {
        modifierGroupItem.is_show_modifier = !modifierGroupItem.is_show_modifier;
      } else {
        modifierGroup =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3];
        modifierGroupItem =
          itemSelect.modifier_group[index].items[index2].item_in_mog
            .modifier_group[index3].items[index4];
      }

      if (modifierGroup && modifierGroupItem) {
        if (!modifierGroupItem.is_not_available) {
          if (
            !modifierGroupItem.selected &&
            modifierGroup.total_selected >= modifierGroup.max_qty
          ) {
            return;
          }

          modifierGroupItem.selected = !modifierGroupItem.selected;
          const modifierGroupItemQty = modifierGroupItem.quantity;

          if (modifierGroupItem.selected) {
            modifierGroup.total_selected += 1;
            this.itemInfo.member_price +=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd *
              itemChoiceQty;
            this.itemInfo.price_default +=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd *
              itemChoiceQty;

            modifierGroup.items_selected.push(index2);

            if (index3 == -1) {
              modifierGroupItem.item_in_mog = await this.gotoOrderDetail(
                this.categoryId,
                item.id,
                item,
                itemChoiceQty
              );
            }
          } else {
            modifierGroup.total_selected -= modifierGroupItem.quantity;
            this.itemInfo.member_price -=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd *
              itemChoiceQty;
            this.itemInfo.price_default -=
              modifierGroupItem.price_default *
              modifierGroupItemQty *
              parentTotalSelectd *
              itemChoiceQty;

            if (index3 == -1) {
              if (
                modifierGroupItem.item_in_mog &&
                modifierGroupItem.item_in_mog.modifier_group
              ) {
                modifierGroupItem.item_in_mog.modifier_group.forEach(
                  (group) => {
                    group.items.forEach((itemModifier) => {
                      if (itemModifier.selected) {
                        itemModifier.selected = false;
                        this.itemInfo.price_default -=
                          itemModifier.price_default *
                          itemModifier.quantity *
                          modifierGroupItemQty *
                          itemChoiceQty;
                        this.itemInfo.member_price -=
                          itemModifier.price_default *
                          itemModifier.quantity *
                          modifierGroupItemQty *
                          itemChoiceQty;
                      }
                    });
                  }
                );
              }

              modifierGroupItem.item_in_mog = {};
            }

            modifierGroupItem.quantity = 1;
            modifierGroup.items_selected.splice(
              modifierGroup.items_selected.indexOf(index2)
            );
          }

          this.checkAvailableAddToCart();
        }
      }
    },

    addItem() {
      this.itemInfo.quantity += 1;
    },

    removeItem() {
      this.itemInfo.quantity -= 1;
    },

    addToCart() {
      if (this.itemInfo.disable_add_to_cart) {
        let mess = "Please make a selection for ";

        this.itemInfo.modifier_group.forEach((item) => {
          if (item.min_qty > item.total_selected) {
            mess += item.name;
            return;
          }
        });

        alert(mess);
      } else {
        if (this.itemInfo.choice) {
          this.choiceSelected.items.forEach((item) => {
            let itemInfo = item.item_detail;
            let checkOrderSuccess = true;
            // let modifier_group_full = JSON.parse(JSON.stringify(itemInfo.modifier_group));

            // itemInfo.modifier_group_full = modifier_group_full;

            itemInfo.modifier_group.forEach((item) => {
              if (item.total_selected < item.min_qty) {
                checkOrderSuccess = false;
                return;
              }
            });

            if (!checkOrderSuccess) {
              return;
            }

            itemInfo.modifier_group = itemInfo.modifier_group.filter(
              (modifierGroup, index) => {
                if (modifierGroup.total_selected == 0) {
                  return false;
                } else {
                  itemInfo.modifier_group[
                    index
                  ].items = modifierGroup.items.filter((item) => {
                    if (!item.selected) {
                      return false;
                    }

                    if (item.item_in_mog && item.item_in_mog.modifier_group) {
                      item.item_in_mog.modifier_group = item.item_in_mog.modifier_group.filter(
                        (subModifierGroup, subIndex) => {
                          if (subModifierGroup.total_selected == 0) {
                            return false;
                          } else {
                            item.item_in_mog.modifier_group[
                              subIndex
                            ].items = subModifierGroup.items.filter(
                              (subItem) => {
                                if (!subItem.selected) {
                                  return false;
                                }

                                return true;
                              }
                            );
                          }

                          return true;
                        }
                      );
                    }

                    return true;
                  });
                }

                return true;
              }
            );

            // itemInfo.modifier_group_full = itemInfo.modifier_group_full.filter(
            //   (modifierGroup, index) => {
            //     if (modifierGroup.total_selected == 0) {
            //       return false;
            //     } else {
            //       itemInfo.modifier_group_full[
            //         index
            //       ].items = modifierGroup.items.filter((itemMog) => {
            //         if (itemMog.selected) {
            //           return false;
            //         }

            //         return true;
            //       });
            //     }

            //     return true;
            //   }
            // );
          });
        } else {
          let checkOrderSuccess = true;
          //let modifier_group_full = JSON.parse(JSON.stringify(this.itemInfo.modifier_group));

          //this.itemInfo.modifier_group_full = modifier_group_full;

          this.itemInfo.modifier_group.forEach((item) => {
            if (item.total_selected < item.min_qty) {
              checkOrderSuccess = false;
              return;
            }
          });

          if (!checkOrderSuccess) {
            return;
          }

          this.itemInfo.modifier_group = this.itemInfo.modifier_group.filter(
            (modifierGroup, index) => {
              if (modifierGroup.total_selected == 0) {
                return false;
              } else {
                this.itemInfo.modifier_group[
                  index
                ].items = modifierGroup.items.filter((item) => {
                  if (!item.selected) {
                    return false;
                  }

                  if (item.item_in_mog && item.item_in_mog.modifier_group) {
                    item.item_in_mog.modifier_group = item.item_in_mog.modifier_group.filter(
                      (subModifierGroup, subIndex) => {
                        if (subModifierGroup.total_selected == 0) {
                          return false;
                        } else {
                          item.item_in_mog.modifier_group[
                            subIndex
                          ].items = subModifierGroup.items.filter((subItem) => {
                            if (!subItem.selected) {
                              return false;
                            }

                            return true;
                          });
                        }

                        return true;
                      }
                    );
                  }

                  return true;
                });
              }

              return true;
            }
          );

          // this.itemInfo.modifier_group_full = this.itemInfo.modifier_group_full.filter(
          //   (modifierGroup, index) => {
          //     if (modifierGroup.total_selected == 0) {
          //       return false;
          //     } else {
          //       this.itemInfo.modifier_group_full[
          //         index
          //       ].items = modifierGroup.items.filter((item) => {
          //         if (item.selected) {
          //           return false;
          //         }

          //         return true;
          //       });
          //     }

          //     return true;
          //   }
          // );
        }

        this.itemInfo.choice_selected = this.choiceSelected;
        this.itemInfo.choices = null;

        let orderInfo =
          JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
        const objectInArrayIndex = orderInfo.findIndex((item) => {
          if (this.compareObjects(item, this.itemInfo)) {
            item.quantity += this.itemInfo.quantity;
            return true;
          }
          return false;
        });
        if (objectInArrayIndex !== -1) {
          localStorage.removeItem(this.cartName);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
        } else {
          orderInfo.push(this.itemInfo);
          localStorage.setItem(this.cartName, JSON.stringify(orderInfo));
        }
        EventBus.emit("AddToCartSuccess");
        EventBus.emit("AddToCartThenReloadCategorySuccess", {
          time: this.reloadModal,
        });
        this.closeModel();
      }
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    clickLink(slide) {
      if (slide && slide.destination_link != "") {
        window.open(slide.destination_link);
      } else if (
        slide &&
        slide.direct_to_menu_item != "" &&
        !slide.direct_to_suggested_adds_on
      ) {
        this.showLoader();
        let dataForm = {
          menuset_id: this.branch.menuset_id ?? 0,
          category_id: this.categoryId ?? 0,
          item_id: slide.direct_to_menu_item ?? 0,
          branch_code: this.$store.state.branchCode,
        };

        OrderService.getItemDetail(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.isLoadDone = true;
              this.itemInfo = response.data.data;
              this.itemInfo.price_default = parseFloat(
                this.itemInfo.price_default
              );
              this.itemInfo.member_price = parseFloat(
                this.itemInfo.member_price
              );
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      }
    },

    getModifierToShowSlide(modifierGroups) {
      // Iterate through modifier groups
      for (const group of modifierGroups) {
        // Find item with price > 0
        const itemWithPrice = group.items.find((subItem) => subItem.price > 0);
        if (itemWithPrice) {
          return itemWithPrice;
        }
      }

      // If no item with price > 0 return the first item from the first group
      const firstItem = modifierGroups[0].items[0];

      return firstItem;
    },

    calculateTotalStockBalanceForFoundItems(itemInfo) {
      let orderInfo =
        JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      let totalStockBalance = 0;
      for (let item of orderInfo) {
        if (item.id === itemInfo.id) {
          totalStockBalance += item.quantity;
        }
      }
      return totalStockBalance;
    },

    calculateTotalStockBalanceForFoundItemsModifier(itemInfo) {
      let orderInfo =
        JSON.parse(localStorage.getItem(this.cartName, null)) ?? [];
      let totalStockBalance = 0;
      for (let i = 0; i < orderInfo.length; i++) {
        if (orderInfo[i] && orderInfo[i].modifier_group) {
          for (let j = 0; j < orderInfo[i].modifier_group.length; j++) {
            if (orderInfo[i].modifier_group[j].items) {
              for (
                let k = 0;
                k < orderInfo[i].modifier_group[j].items.length;
                k++
              ) {
                if (
                  orderInfo[i].modifier_group[j].items[k].id === itemInfo.id
                ) {
                  totalStockBalance +=
                    orderInfo[i].modifier_group[j].items[k].quantity;
                  // return;
                }
              }
            }
          }
        }
      }
      return totalStockBalance;
    },

    checkAvailableItem() {
      const item = this.itemInfo;
      var stockBalance =
        this.itemInfo.stock_balance -
        this.calculateTotalStockBalanceForFoundItems(this.itemInfo);

      if (item.stock_enable_balance) {
        // countdown = true
        if (item.stock_balance > 0) {
          if (stockBalance > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        // countdown = false
        if (item.is_not_available == 0) {
          return true;
        } else {
          return false;
        }
      }
    },

    checkAvailableModifierItem(item) {
      var stockBalance =
        item.stock_balance -
        this.calculateTotalStockBalanceForFoundItemsModifier(item);
      if (item.stock_enable_balance) {
        // countdown = true
        if (item.stock_balance > 0) {
          if (stockBalance > 0) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        // countdown = false
        if (item.is_not_available == 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    checkAvailableAddToCart() {
      let check = false;

      if (this.itemInfo.choice) {
        this.choiceSelected.items.forEach((item) => {
          let itemInfo = item.item_detail;

          itemInfo.modifier_group.forEach((item) => {
            if (item.min_qty > item.total_selected) {
              check = true;
              return;
            }
          });
        });
      } else {
        this.itemInfo.modifier_group.forEach((item) => {
          if (item.min_qty > item.total_selected) {
            check = true;
            return;
          }
        });
      }

      this.itemInfo.disable_add_to_cart = check;
    },

    compareObjects(obj1, obj2) {
      if (obj1.choice != obj2.choice) {
        return false;
      }

      // Check main item ID
      if (obj1.id !== obj2.id) {
        return false;
      }

      // Check main item price
      if (this.isLogined) {
        if (obj1.member_price != obj2.member_price) {
          return false;
        }
      } else {
        if (obj1.price_default != obj2.price_default) {
          return false;
        }
      }

      if (obj2.choice) {
        // Compare choice_selected
        if (
          obj1.choice_selected.id !== obj2.choice_selected.id ||
          obj1.choice_selected.default_price !==
            obj2.choice_selected.default_price ||
          obj1.choice_selected.member_price !==
            obj2.choice_selected.member_price
        ) {
          return false;
        }

        // Compare items in choice_selected
        const items1 = obj1.choice_selected.items;
        const items2 = obj2.choice_selected.items;

        for (let i = 0; i < items1.length; i++) {
          if (
            items1[i].id !== items2[i].id ||
            items1[i].qty !== items2[i].qty
          ) {
            return false;
          }

          let itemDetail1 = items1[i].item_detail;
          let itemDetail2 = items2[i].item_detail;

          // Compare modifiers in item
          if (itemDetail1.modifier_group && itemDetail2.modifier_group) {
            if (
              itemDetail1.modifier_group.length !=
              itemDetail2.modifier_group.length
            ) {
              return false;
            }

            for (let j = 0; j < itemDetail1.modifier_group.length; j++) {
              if (
                itemDetail1.modifier_group[j].id !=
                  itemDetail2.modifier_group[j].id ||
                itemDetail1.modifier_group[j].total_selected !=
                  itemDetail2.modifier_group[j].total_selected
              ) {
                return false;
              }

              let itemModifiers1 = itemDetail1.modifier_group[j].items;
              let itemModifiers2 = itemDetail2.modifier_group[j].items;

              if (itemModifiers1.length != itemModifiers2.length) {
                return false;
              }

              for (let k = 0; k < itemModifiers1.length; k++) {
                if (
                  itemModifiers1[k].id != itemModifiers2[k].id ||
                  itemModifiers1[k].quantity != itemModifiers2[k].quantity
                ) {
                  return false;
                }
              }
            }
          }
        }
      } else {
        // Compare items in modifier_group
        if (obj1.modifier_group.length !== obj2.modifier_group.length) {
          return false;
        }

        for (let i = 0; i < obj1.modifier_group.length; i++) {
          const group1 = obj1.modifier_group[i];
          const group2 = obj2.modifier_group[i];
          if (group1.items.length !== group2.items.length) {
            return false;
          }

          for (let j = 0; j < group1.items.length; j++) {
            if (
              group1.items[j].id !== group2.items[j].id ||
              group1.items[j].quantity !== group2.items[j].quantity
            ) {
              return false;
            }
          }
        }
      }

      // All checks passed
      return true;
    },
  },
  watch: {
    reloadModal() {
      this.choiceSelected = {};

      if (this.itemId) {
        this.itemInfo = this.itemDetail;
        this.originalChoices = JSON.stringify(this.itemDetail.choices);

        if (this.itemInfo.choice) {
          this.selectChoice(0);
        }

        if (this.itemInfo.slides) {
          var usedItems = [];
          this.itemInfo.slides.forEach((slide) => {
            if (slide.direct_to_suggested_adds_on === 1) {
              this.itemInfo.modifier_group.forEach((modifierGroup) => {
                const selectedItem = modifierGroup.items.find(
                  (item) => item.price > 0 && !usedItems.includes(item.id)
                );
                if (selectedItem) {
                  usedItems.push(selectedItem.id);
                  slide.itemSelected = selectedItem;
                } else {
                  const selectedItemPriceIsZero = modifierGroup.items.find(
                    (item) => item.price == 0 && !usedItems.includes(item.id)
                  );
                  if (selectedItemPriceIsZero) {
                    usedItems.push(selectedItemPriceIsZero.id);
                    slide.itemSelected = selectedItemPriceIsZero;
                  }
                }
              });
            }
          });
        }

        this.itemInfo.price_default = parseFloat(this.itemInfo.price_default);
        this.itemInfo.member_price = parseFloat(this.itemInfo.member_price);

        this.isLoadDone = true;
      } else {
        this.isLoadDone = false;
      }
    },
  },
};
</script>

<style scoped>
/* Css model */
.modal.modal-full .modal-content {
  border-radius: 0;
  border: none;
}

/* Full Screen */
.modal.modal-full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.modal-full .modal-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.modal-full .modal-content .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal.modal-full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

.modal.modal-full.fade .modal-dialog {
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s 0.5s;
}

.modal.modal-full.fade.show .modal-dialog {
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: opacity 0.5s ease, visibility 0s;
}

.modal.modal-full.fade .modal-dialog {
  bottom: 0;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}

.btn-close-modal {
  position: absolute;
  background-color: #f8f8f8;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
  z-index: 999999;
}

.btn-close-modal div {
  font-size: 32px !important;
  font-weight: bold;
  color: #124728 !important;
  padding-right: 0 !important;
  margin-bottom: 4.5px;
}

/* CSS item detail */
.page-header {
  position: relative;
  background: #ffffff;
  height: unset !important;
  overflow-y: unset !important;
  padding-bottom: 0 !important;
}

.page-header .container {
  padding: 0 !important;
}

.page-header .container .content {
  margin-bottom: 85px;
}

.item-img-cont {
  position: relative;
  max-height: 590px !important;
}

.item-img-container .btn-back {
  position: absolute;
  top: 5px;
  left: 0px;
  cursor: pointer;
}

.item-img-container img {
  width: 100%;
  height: auto;
  max-height: 400px;
}

.item-img-container .item-name {
  font-size: 20px;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}

:deep(.signature-highlight) {
  display: flex !important;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #124728;
  color: #ffffff;
  height: 15px;
}

:deep(.signature-highlight div) {
  font-size: 9px !important;
  line-height: 13px;
  font-weight: bold;
}

:deep(.signature-highlight i) {
  font-size: 11px;
  margin-top: 3px;
}

:deep(.highlight-banner) {
  display: flex;
  width: 100% !important;
  overflow-x: auto;
  padding: 12px 24px;
  background-color: #fcf1f9;
}

:deep(.highlight-banner img) {
  min-width: 100%;
  height: 100px;
}

:deep(.highlight-banner div:not(:first-child)) {
  padding-left: 15px;
}

:deep(.accordion > div:first-child) {
  padding-bottom: 5px;
}

:deep(.accordion > div) {
  cursor: pointer;
}

:deep(.accordion .option-item) {
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  margin-top: 10px;
}

:deep(.accordion .option-item > div:first-child) {
  padding-right: 0;
}

:deep(.accordion .option-item i),
:deep(.total-item i) {
  font-size: 13px;
  font-weight: bold;
}

:deep(.accordion .option-item .item-quantity),
:deep(.total-item .item-quantity) {
  width: 27px;
  text-align: center;
  border-radius: 3px;
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
  padding: 4px 2px;
  margin: 0 3px;
  border: none;
}

:deep(.total-item .item-quantity) {
  font-size: 18px;
  width: 35px;
  font-weight: bold;
  margin: 0 5px;
  font-family: "Inter", sans-serif;
}

:deep(.total-item i) {
  font-size: 18px;
}

.add-to-cart {
  position: fixed;
  left: 0;
  z-index: 99999;
  width: 100%;
  border-top: 2px solid #f1f1f1;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 1em;
  padding-right: 1em;
}

.btn-add-to-cart {
  width: fit-content !important;
  text-transform: unset !important;
  background-color: transparent !important;
  border-color: #124728 !important;
  box-shadow: unset !important;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding: 10px 50px;
  border-radius: 10px;
  border: 1px solid #124728 !important;
}

.btn-add-to-cart.disable {
  background: radial-gradient(
    343px at 46.3% 47.5%,
    rgb(242, 242, 242) 0%,
    rgb(241, 241, 241) 72.9%
  );
  color: gray !important;
}

:deep(.break-line) {
  width: auto;
  height: 8px;
  background-color: #f1f1f1;
  margin-left: 1em;
  margin-right: 1em;
}

:deep(.fs-18) {
  font-size: 16px !important;
}

:deep(.fs-16) {
  font-size: 16px !important;
}

:deep(.fs-13) {
  font-size: 13px !important;
}

:deep(.fs-11) {
  font-size: 11px !important;
}

:deep(.select-min) {
  font-weight: 500;
  color: #eb2f06 !important;
}

:deep(.disabled-item) {
  opacity: 0.5;
}

:deep(.item-img-container .item-description) {
  font-size: 13.5px !important;
  color: #666666 !important;
  font-family: "Inter", sans-serif;
}

:deep(.item-img-container .txt-from) {
  font-size: 11px !important;
  color: gray !important;
  font-family: "Inter", sans-serif;
}

:deep(.modifierGroupName) {
  font-family: "Inter", sans-serif;
}

:deep(.modifierGroupSignature) {
  font-family: "Inter", sans-serif;
}

:deep(.font-inter) {
  font-family: "Inter", sans-serif;
  color: #666666;
}

:deep(.font-inter-and-selected) {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: #666666;
}

:deep(.font-inter-and-not-selected) {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  color: #666666 !important;
}

:deep(.item-image) {
  height: 300px;
  width: auto;
  overflow: hidden;
  background-size: cover;
  background-position: center bottom;
  transition: transform 3s ease;
  transform-origin: center;
}

:deep(.icon-check) {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("~@/assets/img/my-cart/icon-check-green.svg");
  width: 16px;
  height: 16px;
}

:deep(.item-name-top) {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  z-index: 99999;
  background: #ffffff;
  opacity: 0;
  padding-left: 50px;
  padding-right: 30px;
  box-shadow: 0 2px 6px 0 rgba(28, 28, 28, 0.1);
}

:deep(.item-name-top.active) {
  opacity: 1;
  transition: opacity 0.2s;
}

:deep(.item-name-top > div) {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.pad-left-right) {
  padding-left: 1em;
  padding-right: 1em;
}

:deep(.modifier-not-available) {
  opacity: 0.5 !important;
}

:deep(.modifier-not-available > div > span:first-child) {
  text-decoration: line-through !important;
}

:deep(.image-container) {
  width: 100%;
  height: auto;
  max-height: 400px;
  overflow: hidden;
  position: relative;
}

:deep(.food-image) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: zoomOut 1.5s ease-out forwards;
}

:deep(.choice-list > div) {
  padding: 5px 10px;
  color: #bdbdbd;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
}

:deep(.choice-list > div.active) {
  background-color: #124728;
  color: #ffffff;
  border: none;
}

@keyframes zoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 480px) {
  :deep(.image-container) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
}
</style>
